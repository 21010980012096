import { useQuery, useQueryClient, useMutation } from "react-query";
import { useClient } from "context/authContext";
import { alertSuccess, alertError } from "utils/alerts";

function useBusinessData(businessesId) {
  const client = useClient();
  const data = {
    businessesId: businessesId,
  };
  return useQuery(
    ["businessData", businessesId],
    () => client("getBusinessById", { data }),
    {
      enabled: !!businessesId,
    }
  );
}

function useUpdateBusiness() {
  const client = useClient();
  const queryClient = useQueryClient();

  const updateBusiness = (data) => {
    return client("updateBusiness", { data });
  };

  return useMutation(updateBusiness, {
    onSuccess: () => {
      alertSuccess("Settings updated");
    },
    onError: (err) => {
      alertError(err.error);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["businessData", 0]);
    },
  });
}

export { useBusinessData, useUpdateBusiness };
