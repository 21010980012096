import { useQuery } from "react-query";
import { useClient } from "../context/authContext";
import axios from "axios";

function useGoogleAnalytics(
  viewId: string,
  startDate: string,
  endDate: string,
  ga_options: any[],
  queryKey?: string
) {
  const client = useClient();

  const googleAuthToken = useQuery("googleAuthTest", () => {
    return client("googleAuthTest");
  });

  const token = googleAuthToken?.data?.access_token;

  const formatReportRequest = () => {
    return ga_options?.map((option) => {
      const result = {
        viewId: viewId || null,
        dateRanges: [{ startDate: startDate, endDate: endDate }],
        dimensions: option.dimensions,
        metrics: option.metrics,
      };
      return result;
    });
  };

  const getGoogleData = async () => {
    const body = {
      reportRequests: [
        formatReportRequest(),

        // {
        //   viewId: viewId,
        //   dateRanges: [{ startDate: startDate, endDate: endDate }],
        //   dimensionFilterClauses: [
        //     {
        //       filters: [
        //         {
        //           dimension_name: "ga:adwordsCampaignID",
        //           operator: "EXACT",
        //           expressions: ["1619746302"],
        //         },
        //       ],
        //     },
        //   ],
        //   dimensions: [{ name: "ga:date" }, { name: "ga:adwordsCampaignID" }],
        //   metrics: [{ expression: "ga:CTR" }],
        // },
        // {
        //   viewId: viewId,
        //   dateRanges: [{ startDate: startDate, endDate: endDate }],
        //   dimensionFilterClauses: [
        //     {
        //       filters: [
        //         {
        //           dimension_name: "ga:adwordsCampaignID",
        //           operator: "EXACT",
        //           expressions: ["1619746302"],
        //         },
        //       ],
        //     },
        //   ],
        //   dimensions: [{ name: "ga:date" }, { name: "ga:adwordsCampaignID" }],
        //   metrics: [{ expression: "ga:CPC" }],
        // },
        // {
        //   viewId: viewId,
        //   dateRanges: [{ startDate: startDate, endDate: endDate }],
        //   dimensionFilterClauses: [
        //     {
        //       filters: [
        //         {
        //           dimension_name: "ga:adwordsCampaignID",
        //           operator: "EXACT",
        //           expressions: ["1619746302"],
        //         },
        //       ],
        //     },
        //   ],
        //   dimensions: [{ name: "ga:date" }, { name: "ga:adwordsCampaignID" }],
        //   metrics: [{ expression: "ga:adClicks" }],
        // },
        // {
        //   viewId: viewId,
        //   dateRanges: [{ startDate: startDate, endDate: endDate }],
        //   dimensionFilterClauses: [
        //     {
        //       filters: [
        //         {
        //           dimension_name: "ga:adwordsCampaignID",
        //           operator: "EXACT",
        //           expressions: ["1619746302"],
        //         },
        //       ],
        //     },
        //   ],
        //   dimensions: [{ name: "ga:date" }, { name: "ga:adwordsCampaignID" }],
        //   metrics: [{ expression: "ga:impressions" }],
        // },
        // {
        //   viewId: viewId,
        //   dateRanges: [{ startDate: startDate, endDate: endDate }],
        //   dimensionFilterClauses: [
        //     {
        //       filters: [
        //         {
        //           dimension_name: "ga:adwordsCampaignID",
        //           operator: "EXACT",
        //           expressions: ["1619746302"],
        //         },
        //       ],
        //     },
        //   ],
        //   dimensions: [
        //     { name: "ga:date" },
        //     { name: "ga:adwordsCampaignID" },
        //     { name: "ga:adMatchedQuery" },
        //   ],
        //   metrics: [{ expression: "ga:adClicks" }],
        // },
      ],
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(
      `https://analyticsreporting.googleapis.com/v4/reports:batchGet`,
      body,
      { headers }
    );
    return data;
  };

  const googleData = useQuery(
    ["googleStats", [startDate, queryKey]],
    () => {
      return getGoogleData();
    },
    {
      refetchOnMount: "always",
      enabled: !!viewId,
    }
  );

  return googleData;
}

export { useGoogleAnalytics };
