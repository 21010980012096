import { useState, useContext } from "react";
import { LocationContext } from "context/locationContext";
import ProductNav from "Components/ProductNav";
import { Outlet } from "react-router-dom";
import ProductHeader from "Components/ProductHeader";
const PPC = () => {
  const location: any = useContext(LocationContext);
  console.log("location", location);

  return (
    <>
      <ProductHeader location={location} />
      <main className="flex-1 overflow-y-auto">
        <section
          aria-labelledby="primary-heading"
          className="min-w-0 flex-1 h-full flex flex-col lg:order-last"
        >
          <Outlet />
        </section>
      </main>
    </>
  );
};

export default PPC;
