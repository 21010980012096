import { useQuery } from "react-query";
import { useClient } from "../../context/authContext";

export default function useGetAccounts(limit = 10, offset = 0, query = "") {
  console.log("get accounts");
  const client = useClient();
  const account = useQuery([`getAccounts-${query}`, offset], () =>
    client(
      `getAccounts?offset=${offset}&limit=${limit}&status=active&query=${query}`
    )
  );
  return account;
}
