import { Path, useForm, UseFormRegister, SubmitHandler } from "react-hook-form";
import { forwardRef } from "react";

interface IFormValues {
  name?: string;
  label: string;
  options: { label: string; value: string }[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  defaultValue?: string | null;
}

const Select = forwardRef<
  HTMLSelectElement,
  { label: string; options: any; defaultValue: any } & ReturnType<
    UseFormRegister<IFormValues>
  >
>(({ onChange, onBlur, name, label, options, defaultValue = null }, ref) => (
  <>
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <select
      name={name}
      ref={ref}
      onChange={onChange}
      onBlur={onBlur}
      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      defaultValue={defaultValue}
    >
      {options.map((optionData) => (
        <option key={optionData.value} value={optionData.value}>
          {optionData.label}
        </option>
      ))}
    </select>
  </>
));

export default Select;
