import SubPageHeading from "Components/SubPageHeading";
import { useState, useContext, useEffect } from "react";
import StatBox from "Modules/Stats/StatBox/StatBox";
import useReviewStats from "api/useReviewStats";
import { useGetReviewReport } from "api/useReviews";
import LineGraph from "Modules/Stats/LineGraph/LineGraph";
import { formatReviewStats, formatReviewSources } from "utils/dataHelpers";
import PieGraph from "Modules/Stats/PieGraph/PieGraph";
import Fullpagespinner from "Components/Loaders/FullPageSpinner";
import ReputationNav from "Modules/Reputation/ReputationNav";
import { LocationContext } from "context/locationContext";
import { useParams } from "react-router-dom";
import ErrorBoundary from "utils/errorBoundry";

const ReputationStats = () => {
  // const location = useContext(LocationContext);
  const location = useParams();

  const locationId = location.id;

  const { data: reviewStats, isLoading: isReviewStatsLoading } =
    useReviewStats(locationId);

  const { data: reviewReport } = useGetReviewReport(locationId);

  const lineData = formatReviewStats(reviewStats);
  const pieData = formatReviewSources(reviewStats?.siteTotals);

  return (
    <>
      <div className="px-8 pb-8">
        <SubPageHeading
          title="Reputation Stats"
          body="View your recent stats from reviews, text messages to track your progress"
        />
      </div>
      <ReputationNav />

      {isReviewStatsLoading ? (
        <Fullpagespinner />
      ) : (
        <div className="grid grid-cols-3">
          <ErrorBoundary>
            <StatBox
              name="Total Reviews"
              stat={reviewReport?.reviews_count}
              bgColor="pelorous"
              statColor="pelorous"
              iconColor="advertising-300"
              labelColor="shadow"
            />
          </ErrorBoundary>
          <StatBox
            name="Average Rating"
            stat={reviewReport?.rating}
            bgColor="reputation-100"
            statColor="reputation-300"
            iconColor="reputation-300"
            labelColor="shadow"
          />
          <ErrorBoundary>
            <StatBox
              name="Average Rating"
              stat={reviewReport?.rating}
              bgColor="search-100"
              statColor="search-300"
              iconColor="search-300"
              labelColor="shadow"
            />
          </ErrorBoundary>
          <div className="col-span-2 border-b border-r">
            <ErrorBoundary>
              {lineData && (
                <LineGraph
                  name="Messages Sent vs New Reviews"
                  data={lineData}
                  spacing="every month"
                />
              )}
            </ErrorBoundary>
          </div>
          <ErrorBoundary>
            <div className="col-span-1 border-b">
              <PieGraph data={pieData} name="Clicks By Site" />
            </div>
          </ErrorBoundary>
        </div>
      )}
    </>
  );
};

export default ReputationStats;
