import { statuses } from "utils/constants";

export function formatDateAndTime(date) {
  date = new Date(date);
  let year = date.getFullYear();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let formatedDate = `${month}/${day}/${year}`;
  return formatedDate;
}

export function formatDate(date) {
  date = date.slice(0, 4) + "," + date.slice(4, 6) + "," + date.slice(6, 8);
  date = new Date(date);
  let year = date.getFullYear();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let formatedDate = `${month}/${day}/${year}`;
  return formatedDate;
}

export const formatDateWithDashes = (date) =>
  `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`;

export const filterNonActiveProducts = (products) => {
  return products.filter((product) => product?.statusesId === statuses.active);
};
