import React from "react";

import { LoginForm } from "../../Modules/LoginForm/LoginForm";
import Logo from "../../logo.svg";
import LoginAnimation from "Components/LoginAnimation/LoginAnimation";

const Login = () => {
  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img src={Logo} alt="Goldn Logo" width="70px" />

            <h2 className="mt-6 text-3xl font-extrabold text-primary">
              Sign in to your account
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Or{" "}
              <a
                href="http://getgoldn.com"
                className="font-medium text-primary hover:text-indigo-500"
              >
                Learn how to get started
              </a>
            </p>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <LoginForm />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1 bg-gray-50">
        <LoginAnimation />
      </div>
    </div>
  );
};

export default Login;
