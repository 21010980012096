/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment, useState } from "react";
import {
  CogIcon,
  CollectionIcon,
  HomeIcon,
  MenuAlt2Icon,
  PhotographIcon,
  PlusSmIcon,
  UserGroupIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";
import Breadcrumbs from "Components/Breadcrumbs";
import TopNav from "Components/Navigations/TopNav";
import LocationSearch from "Modules/LocationSearch/LocationSearch";
import { useAuth } from "context/authContext";
import MobileNav from "Components/Navigations/MobileNav";
import SidebarNav from "Components/Navigations/SidebarNav";
import Alert from "Components/Alert/Alert";
import StatusMessage from "Components/StatusMessage";

type Props = {
  title: string;
  padding?: boolean;
  children?: React.ReactNode;
};

export default function Container3({ title, padding = true, children }: Props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { user, logout } = useAuth();
  return (
    <>
      <StatusMessage />
      <div className="h-screen flex">
        {/* Narrow sidebar */}
        <SidebarNav />

        <MobileNav
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
        />

        {/* Content area */}
        <div className="flex-1 flex flex-col overflow-scroll pb-24">
          <header className="w-full">
            <div className="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex-1 flex justify-between px-4 sm:px-6">
                <div className="flex-1 flex">
                  <LocationSearch />
                </div>
                <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
                  {/* Profile dropdown */}
                  <TopNav user={user} logout={logout} />
                </div>
              </div>
            </div>
          </header>
          {/* <div className="border border-b h-20 flex p-6">
            <Breadcrumbs />
          </div> */}

          {/* Main content */}
          <div className="">{children}</div>
        </div>
      </div>
    </>
  );
}
