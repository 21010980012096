import React from "react";

const Textarea = React.forwardRef(
  ({ name, label, placeholder, ...rest }, ref) => {
    return (
      <>
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
        <textarea
          name={name}
          placeholder={placeholder}
          {...rest}
          rows={3}
          className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          ref={ref}
        />
      </>
    );
  }
);

export default Textarea;
