import Fullpagespinner from "Components/Loaders/FullPageSpinner";
import Pagination from "Components/Pagination/Pagination";
import { useBusinessData } from "api/useBusinessData";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEmailMessages, useDeleteEmailMessage } from "api/useEmailMessages";
import MessageList from "Modules/Reputation/MessageList";
import EmailForm from "Modules/Reputation/ReputationEmails/EmailForm";
import SubPageHeading from "Components/SubPageHeading";
import ReputationNav from "Modules/Reputation/ReputationNav";
import { useAuth } from "context/authContext";

const ReputationEmails = () => {
  let { id } = useParams();
  const { user } = useAuth();

  const { data: location } = useBusinessData(id);
  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 10;
  const { data: emailMessages, isLoading } = useEmailMessages(
    user.id,
    PageSize,
    currentPage
  );

  const { mutate: deleteEmail, status } = useDeleteEmailMessage();

  const handleDelete = (emailMessageId) => {
    const emailMessage = {
      emailMessageId,
    };
    deleteEmail(emailMessage);
  };

  if (isLoading) {
    return <Fullpagespinner />;
  }
  return (
    <div>
      <div className="px-8 pb-8">
        <SubPageHeading
          title="Email Messages"
          body="Send
         and track email messages."
        />
      </div>
      <ReputationNav />
      <EmailForm location={location} />
      <MessageList
        data={emailMessages.emailMessages}
        type="email"
        handleDelete={handleDelete}
        status={status}
      />
      <Pagination
        currentPage={currentPage}
        totalCount={emailMessages.totalEmails}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default ReputationEmails;
