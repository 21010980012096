import { useQuery } from "react-query";
import { useClient } from "../context/authContext";
import axios from "axios";

function useGoogleAnalyticsGA4(
  propertyID: string,
  startDate: string,
  endDate: string,
  ga_options: any[],
  queryKey?: string
) {
  const client = useClient();

  const googleAuthToken = useQuery("googleAuthTest", () => {
    return client("googleAuthTest");
  });

  const token = googleAuthToken?.data?.access_token;

  const formatReportRequest = () => {
    return ga_options?.map((option) => {
      const result = {
        dateRanges: [{ startDate: startDate, endDate: endDate }],
        dimensions: option.dimensions,
        metrics: option.metrics,
        orderBys: option.orderBys,
      };
      return result;
    });
  };

  const getGoogleData = async () => {
    const body = {
      requests: [formatReportRequest()],
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const { data } = await axios.post(
      `https://analyticsdata.googleapis.com/v1beta/properties/${propertyID}:batchRunReports`,
      body,
      { headers }
    );
    return data;
  };

  const googleData = useQuery(
    ["googleStats", [startDate, queryKey]],
    () => {
      return getGoogleData();
    },
    {
      refetchOnMount: "always",
      enabled: !!propertyID && !!token,
    }
  );

  return googleData;
}

export { useGoogleAnalyticsGA4 };
