import { ChevronRightIcon } from "@heroicons/react/solid";
import { OfficeBuildingIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAppData from "api/useAppdata";
import { useAuth } from "context/authContext";

const ResultsList = ({ query, handleQuery, handleReset }) => {
  const { user } = useAuth();
  const { data, isLoading } = useAppData(user.id);

  const [locations, setLocations] = useState(data?.businessData);

  useEffect(() => {
    setLocations(data?.businessData);
  }, [locations, data?.businessData]);

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md z-10 absolute top-16">
      <ul role="list" className="divide-y divide-gray-200">
        {query.length > 2 &&
          locations
            .filter((location) => {
              if (query === "") {
                return location;
              } else if (
                location.commonName
                  .toLowerCase()
                  .includes(query.toLowerCase()) ||
                location.name.toLowerCase().includes(query.toLowerCase())
              ) {
                return location;
              }
            })
            .map((location) => (
              <li key={location.id}>
                <Link
                  to={`/locations/${location.id}`}
                  className="block hover:bg-gray-50"
                  onClick={() => {
                    handleQuery("");
                    handleReset();
                  }}
                >
                  <div className="px-4 py-4 flex items-center sm:px-6">
                    <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                      <div className="truncate">
                        <div className="flex text-sm">
                          <p className="font-medium text-secondary truncate">
                            {location.commonName || location.name}
                          </p>
                        </div>
                        <div className="mt-2 flex">
                          <div className="flex items-center text-sm text-gray-500">
                            <OfficeBuildingIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <p>
                              {location.address} {location.city},{" "}
                              {location.state}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ml-5 flex-shrink-0">
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
      </ul>
    </div>
  );
};

export default ResultsList;
