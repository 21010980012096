import toast from "react-hot-toast";
import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/solid";

function alertSuccess(msg) {
  console.log("sucess!");
  toast.custom(
    (t) => (
      <div
        className={`${
          t.visible ? "-translate-y-6" : "opacity-0"
        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 transition translate-y-0`}
      >
        <div className="flex-1 w-0 p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              <CheckCircleIcon className="h-10 w-10 text-emerald-500" />
            </div>
            <div className="ml-3 flex-1">
              <p className="text-sm font-medium text-emerald-500">Sucess!</p>
              <p className="mt-1 text-sm text-gray-500">{msg}</p>
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          <button
            onClick={() => toast.dismiss(t.id)}
            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-gray-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Close
          </button>
        </div>
      </div>
    ),
    {
      position: "top-right",
    }
  );
}

function alertError(error) {
  toast.custom(
    (t) => (
      <div
        className={`${
          t.visible ? "-translate-y-6" : "opacity-0"
        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 transition translate-y-0`}
      >
        <div className="flex-1 w-0 p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt-0.5">
              <ExclamationIcon className="h-10 w-10 text-red-500" />
            </div>
            <div className="ml-3 flex-1">
              <p className="text-sm font-medium text-red-500">Oops!</p>
              <p className="mt-1 text-sm text-gray-500">{error}</p>
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          <button
            onClick={() => toast.dismiss(t.id)}
            className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-gray-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Close
          </button>
        </div>
      </div>
    ),
    {
      position: "top-right",
      duration: Infinity,
    }
  );
}

export { alertSuccess, alertError };
