import useImpersonate from "api/admin/useImpersonate";
import useGetAccounts from "api/admin/useGetAccounts";
import React, { useState } from "react";
import AccountTable from "./components/AccountTable";
import Pagination from "Components/Pagination/Pagination";
import SearchBar from "Components/SearchBar";

export default function AccountSearch() {
  // const { data, isLoading } = useImpersonate(
  //   "36df0361-7b9f-46cd-97b1-b50cba28938b"
  // );
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");

  const pageSize = 20;

  const { data, isLoading: isLoadingAccounts } = useGetAccounts(
    pageSize,
    currentPage,
    query
  );

  const handleSearch = (query) => {
    setQuery(query.search);
    setCurrentPage(1);
  };

  // const impersonate = () => {
  //   localStorage.setItem("account", JSON.stringify(data));
  //   // eslint-disable-next-line no-restricted-globals
  //   location.reload();
  // };

  return (
    <div className="p-8">
      <div className="sm:flex sm:items-center">
        {/* <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Users
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title,
            email and role.
          </p>
        </div> */}
        {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-secondary px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Add user
          </button>
        </div> */}
      </div>
      {/* <button onClick={() => impersonate()}>Impersonate</button>
      {isLoading ? <div>Loading...</div> : <div>{data?.token}</div>} */}
      {isLoadingAccounts ? (
        <div>Loading...</div>
      ) : (
        <>
          <SearchBar searchFunction={handleSearch} />
          {console.log("data", data)}
          <AccountTable accounts={data?.accounts} />
          <Pagination
            currentPage={currentPage}
            totalCount={data?.totalCount}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
      )}
    </div>
  );
}
