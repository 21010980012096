import Select from "Components/Select";

const dateOptions = [
  { label: "Last 7 days", value: "7daysAgo" },
  { label: "Last 30 days", value: "30daysAgo" },
  { label: "Last 60 days", value: "60daysAgo" },
  { label: "Last 90 days", value: "90daysAgo" },
];

const DateRange = ({ onChange, label = "Current Report" }) => {
  return (
    <Select
      name="Date Range"
      label={label}
      options={dateOptions}
      onChange={onChange}
      onBlur={onChange}
      defaultValue={dateOptions[1].value}
    />
  );
};

export default DateRange;
