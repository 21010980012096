import Fullpagespinner from "Components/Loaders/FullPageSpinner";
import Pagination from "Components/Pagination/Pagination";
import SMSForm from "Modules/Reputation/ReputationSMS/SMSForm";
import { useBusinessData } from "api/useBusinessData";
import { useParams } from "react-router-dom";
import { useState } from "react";
import TextMessageList from "../MessageList";

import { useTextMessages, useDeleteTextMessage } from "api/useTextMessages";
import SubPageHeading from "Components/SubPageHeading";
import ReputationNav from "Modules/Reputation/ReputationNav";
import Alert from "Components/Alert/Alert";

const ReputationsSMS = () => {
  let { id } = useParams();
  const { data: location } = useBusinessData(id);
  const [currentPage, setCurrentPage] = useState(1);
  const PageSize = 10;
  const { data: textMessages, isLoading } = useTextMessages(
    id,
    PageSize,
    currentPage
  );

  const { mutate: deleteText, status } = useDeleteTextMessage();
  const onHandleDeleteText = (textMessageId) => {
    const textMessage = {
      textMessageId,
    };
    deleteText(textMessage);
  };
  if (isLoading) {
    return <Fullpagespinner />;
  }
  return (
    <div>
      <div className="px-8 pb-8">
        <SubPageHeading
          title="Text Messages"
          body="Send and track text messages. The more you send will increase the change of recieving more positive reviews"
        />
      </div>
      <ReputationNav />
      <SMSForm location={location} />
      {textMessages.texts.length ? (
        <>
          <TextMessageList
            data={textMessages.texts}
            type="sms"
            handleDelete={onHandleDeleteText}
            status={status}
          />
          <Pagination
            currentPage={currentPage}
            totalCount={textMessages.totalTexts[0].count}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />{" "}
        </>
      ) : (
        <p>LOOKS LIKE YOU HAVEN'T SENT OUT ANY TEXT MESSAGES YET</p>
      )}
    </div>
  );
};

export default ReputationsSMS;
