import { useState, useCallback, useEffect } from "react";
import { XCircleIcon, PencilAltIcon } from "@heroicons/react/solid";

const ListItem = ({ name, label, data, required, register }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 border-b border-gray-200">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {toggle ? (
          <div className="flex-grow">
            <div className="flex">
              <label htmlFor="email" className="sr-only">
                {label}
              </label>
              <input
                name={label}
                id="email"
                className="shadow-sm w-96 sm:text-sm border-gray-300 border rounded-md p-2"
                placeholder={label}
                {...register(name, { required })}
              />
              <div className="mt-0 sm:mt-0 sm:ml-4 sm:flex-shrink-0 sm:flex sm:items-center">
                <button className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-secondary hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-xs uppercase">
                  update
                </button>
              </div>
            </div>
          </div>
        ) : (
          <span className="flex-grow py-2">{data}</span>
        )}

        <span className="ml-4 flex justify-end">
          {toggle ? (
            <XCircleIcon
              onClick={() => setToggle(!toggle)}
              className="self-center flex-shrink-0 h-5 w-5 text-red-500 mr-2 hover:text-secondary cursor-pointer"
            />
          ) : (
            <PencilAltIcon
              onClick={() => setToggle(!toggle)}
              className="self-center flex-shrink-0 h-5 w-5 text-gray-400 mr-2 hover:text-secondary cursor-pointer"
            />
          )}
        </span>
      </dd>
    </div>
  );
};

export default ListItem;
