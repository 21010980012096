import { useQuery } from "react-query";
import { useClient } from "../context/authContext";

function useCallfire(businessId, limit, offset) {
    console.log('adfasd', businessId)
  const client = useClient();

    const getCalls = async () => {
        if (businessId) {
            const res = await client(`getCalls?label=${businessId}&offset=${offset}&limit=${limit}`)
            return res;
        }
    };

  return useQuery(["getCalls", offset],getCalls);
}

function useCallfireTotalCount(businessId) {
  const client = useClient();

  const getCount = async () => {
      if (businessId) {
          const res = await client(`getCalls?label=${businessId}&fields=totalCount`)
          return res;
      }
  };

  return useQuery("getTotalCount", getCount);
}

export { useCallfire, useCallfireTotalCount };
