import { StarIcon } from "@heroicons/react/solid";

const StarRating = ({ count }) => {
  return (
    <div className="star-rating">
      {[...Array(count)].map((star, index) => {
        return (
          <span key={index} className="star text-amber-300">
            &#9733;
          </span>
        );
      })}
    </div>
  );
};

export default StarRating;
