import React from "react";
import { useCallfire, useCallfireTotalCount } from "api/useCallfire";
import Fullpagespinner from "Components/Loaders/FullPageSpinner";
import Alert from "Components/Alert/Alert";
import Pagination from "Components/Pagination/Pagination";
import { useParams } from "react-router-dom";
import { useState, useContext } from "react";
import { LocationContext } from "context/locationContext";
import CallTrackingTable from "../CallTrackingTable";
import AdvertisingNav from "../AdvertisingNav.tsx";
import SubPageHeading from "Components/SubPageHeading";

const AdvertisingCalls = () => {
  const { id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const { data, isLoading, isError } = useCallfire(id, pageSize, currentPage);
  const { data: totalCount } = useCallfireTotalCount(id);

  if (!data) {
    return <Fullpagespinner />;
  }

  if (isError) {
    return <Alert msg="Could not load data" />;
  }

  return (
    <>
      <SubPageHeading title="Call Tracking" body="Recordings of your calls" />
      <AdvertisingNav />
      <CallTrackingTable calls={data.items} />
      <Pagination
        currentPage={currentPage}
        totalCount={totalCount.totalCount}
        pageSize={pageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </>
  );
};

export default AdvertisingCalls;
