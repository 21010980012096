const PhoneNumber = ({ phoneNumber }) => {
  var cleaned = ("" + phoneNumber).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "1 " : "";
    return (
      <span data-testid="phone-number">
        {[intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")}
      </span>
    );
  }
  return (
    <span className="uppercase text-xs" data-testid="phone-number">
      Non-US Number
    </span>
  );
};

export default PhoneNumber;
