import AccountSearch from "Modules/Admin/AccountSearch/AccountSearch";
import AdminContainer from "Pages/AdminContainer";
const AdminDashboard = () => {
  return (
    <AdminContainer title="Admin Dashboard">
      <AccountSearch />
    </AdminContainer>
  );
};

export default AdminDashboard;
