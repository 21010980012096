import { useGetTicketById } from "api/useZendesk";
import { useParams } from "react-router-dom";
import TicketDetail from "./components/TicketDetail";
import Breadcrumbs from "Components/Breadcrumbs";
import SubPageHeading from "Components/SubPageHeading";

const TicketDetails = () => {
  let { id } = useParams();
  const { data: ticket } = useGetTicketById(id);

  return (
    <>
      {ticket && (
        <TicketDetail ticket={ticket.ticket} comments={ticket.comments} />
      )}
    </>
  );
};

export default TicketDetails;
