import SubPageHeading from "Components/SubPageHeading";
import { Link } from "react-router-dom";
import { MdOutlinePhone, MdMail } from "react-icons/md";
const Contact = () => {
  return (
    <div>
      <div className="space-y-1"></div>
      <div className="bg-white">
        <div className="p-8">
          <SubPageHeading
            title="Get In Touch"
            body="Have a question or need some help? Reach out to our team at
                    anytime."
          ></SubPageHeading>

          {/* <h2 className="text-2xl font-bold text-secondary sm:text-3xl sm:tracking-tight">
                  Get In Touch
                </h2>
                <div className="mt-3">
                  <p className="text-lg text-gray-500">
                    Have a question or need some help? Reach out to our team at
                    anytime.
                  </p>
                </div> */}
          <div className="mt-9">
            <div className="flex">
              <div className="flex-shrink-0">
                <MdOutlinePhone />
              </div>
              <div className="ml-3 text-base text-gray-500">
                <p>(888) 482-6691 ext 2</p>
                <p className="mt-1">8:00am CST and 4:00pm CST</p>
              </div>
            </div>
            <div className="mt-6 flex">
              <div className="flex-shrink-0">
                <MdMail />
              </div>
              <div className="ml-3 text-base text-gray-500">
                <a href="mailto:support@getgoldn.com">support@getgoldn.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
