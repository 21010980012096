import { HomeIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import Logo from "logo.svg";
import { useAuth } from "context/authContext";

const sidebarNavigation = [
  { name: "Home", href: "/", icon: HomeIcon, current: false },
  {
    name: "Support",
    href: "/support",
    icon: QuestionMarkCircleIcon,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SidebarNav = () => {
  const { user } = useAuth();
  return (
    <>
      <div className="hidden w-28 bg-violet overflow-y-auto md:block rounded-xl m-4">
        <div className="w-full py-6 flex flex-col items-center">
          <div className="flex-shrink-0 flex items-center">
            <img className="h-8 w-auto" src={Logo} alt="Goldn Logo" />
          </div>
          <div className="flex-1 mt-6 w-full px-2 space-y-1">
            {sidebarNavigation
              // .filter(
              //   (item) => user.userRole === "admin" && item.name === "Home"
              // )
              .map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "text-secondary"
                      : "text-shadow hover:text-white ",
                    "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  <div className="bg-jacarta p-2 rounded-md hover:bg-secondary">
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-secondary"
                          : "text-shadow group-hover:text-white",
                        "h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                  </div>
                  <span className="mt-2">{item.name}</span>
                </a>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarNav;
