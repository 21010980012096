import KeywordReportTable from "../../Components/KeywordReportTable/KeywordReportTable";
import { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSEOReports, useReportIds } from "../../api/useBrightLocal";
import Alert from "../../Components/Alert/Alert";
import Fullpagespinner from "../../Components/Loaders/FullPageSpinner";
import { formatSearchRankReport } from "../../utils/dataHelpers";
import { LocationContext } from "context/locationContext";
import ProductNav from "Components/ProductNav";
import { Outlet } from "react-router-dom";
import SubPageHeading from "Components/SubPageHeading";
import ProductHeader from "Components/ProductHeader";
import Select from "Components/Select";

const Search = () => {
  const location = useContext(LocationContext);
  const { register, handleSubmit, getValues } = useForm();
  const campaignId = location?.product_seo.brightLocal_CampaignId;

  const [currentReportId, setCurrentReportId] = useState(null);
  const [previousReportId, setPreviousReportId] = useState(null);

  const { searchRankReport, reportIDs, idsLoading } = useSEOReports(
    campaignId,
    currentReportId,
    previousReportId
  );

  if (searchRankReport.isError) {
    return <Alert msg="Oops, something went wrong. Try refreshing the page" />;
  }

  const formatReportIds = (reportIds) => {
    if (reportIds) {
      return reportIds.map((reportId) => {
        return {
          label: reportId.generation_date,
          value: reportId.campaign_history_id,
        };
      });
    }
  };

  const reportIdsFormatted = formatReportIds(reportIDs);

  let formatedReport;

  if (searchRankReport.data) {
    formatedReport = formatSearchRankReport(searchRankReport.data);
  }

  const handleCurrentReport = (e) => {
    console.log("current", e.target.value);
    setCurrentReportId(e.target.value);
  };

  const handlePreviousReport = (e) => {
    console.log("prev", e.target.value);
    setPreviousReportId(e.target.value);
  };
  return (
    <>
      <div className="sticky top-0">
        <ProductHeader location={location} />
      </div>
      <main className="flex-1 overflow-y-auto">
        <section
          aria-labelledby="primary-heading"
          className="min-w-0 flex-1 h-full flex flex-col lg:order-last"
        >
          <SubPageHeading
            title="Search Rank Report"
            body="Stay up-to-date with these helpful Search Ranking reports. Reports are run monthly and you can compare the current report to any previous report.
            "
          />
          {reportIdsFormatted ? (
            <div className="grid grid-cols-2 gap-6 px-6 max-w-4xl">
              <div>
                <Select
                  label="Current Report"
                  options={reportIdsFormatted}
                  onChange={handleCurrentReport}
                />
              </div>
              <div>
                <Select
                  label="Previous Report"
                  options={reportIdsFormatted}
                  onChange={handlePreviousReport}
                />
              </div>
            </div>
          ) : null}

          {formatedReport ? (
            <KeywordReportTable report={formatedReport} />
          ) : (
            <Fullpagespinner />
          )}
        </section>
      </main>
    </>
  );
};

export default Search;
