import { useForm } from "react-hook-form";
import { useAuth } from "context/authContext";
import { useSendTextMessage } from "api/useTextMessages";
import Button from "Components/Button";
import { useEffect, useState } from "react";
import ConfirmationAlert from "Components/Alert/Confirmation";
import ReportHeading from "Components/ReportHeading";

export interface IformState {
  accountId: string;
  business: object;
  phoneNumber: string;
  allowDuplicateSend: boolean;
}

const SMSForm = ({ location }) => {
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState<any>();
  const [allowed, setAllowed] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ criteriaMode: "all" });
  const onSubmit = (formData) => {
    const text = {
      accountId: user.account.id,
      business: location,
      phoneNumber: formData.phoneNumber,
      allowDuplicateSend: false,
    };
    setFormState(text);
    sendText(text);
  };
  const { user } = useAuth();

  const {
    mutate: sendText,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useSendTextMessage();
  useEffect(() => {
    reset({
      phoneNumber: "",
    });
  }, [isSuccess, reset]);

  const toggleConfirmation = () => setOpen(!open);
  useEffect(() => {
    if (isError) {
      toggleConfirmation();
    }
  }, [isError, error]);

  useEffect(() => {
    if (allowed) {
      sendText(formState);
    }
  }, [formState, allowed]);

  const handleSendTextAfterConfirmation = () => {
    setAllowed(true);
    setFormState({ ...formState, allowDuplicateSend: true });
    console.log(formState);
  };
  const alert = {
    title: "Duplicate Phone Number",
    message:
      "You have sent a text to this phone number before. Are you sure you want to resend?",
    confirmButton: "Send",
    cancelButton: "Cancel",
  };
  return (
    <>
      <ConfirmationAlert
        handleConfirmation={() => handleSendTextAfterConfirmation()}
        handleClose={setOpen}
        toggle={open}
        alert={alert}
      />
      <ReportHeading
        title="Send Text Message"
        description="Simply enter a phone number and click “Send Text” to send an
              automated message."
        childComp={
          <form
            className=""
            onSubmit={handleSubmit(onSubmit)}
            data-testid="sms-form"
          >
            <div className="w-full h-8 pb-2">
              <p className="text-sm text-red-600">
                {errors.phoneNumber && (
                  <span className="-mt-8">
                    A valid 10 digit phone number is required
                  </span>
                )}
              </p>
            </div>
            <div className="sm:flex">
              <div className="w-full sm:max-w-xs ">
                <label htmlFor="email" className="sr-only">
                  Phone Number
                </label>
                <input
                  type="tel"
                  data-testid="sms-input"
                  className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md border p-2"
                  placeholder="555-555-5555"
                  {...register("phoneNumber", {
                    required: true,
                    pattern:
                      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                  })}
                />
              </div>
              <Button
                label="Send Text"
                color="primary"
                loading={isLoading}
                disabled={isLoading}
              />
            </div>
          </form>
        }
      />
    </>
  );
};

export default SMSForm;
