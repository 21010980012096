import { useQuery, useMutation, useQueryClient } from "react-query";
import { useClient } from "../context/authContext";
import { alertSuccess, alertError } from "utils/alerts";
function useTextMessages(businessId, limit = 10, offset = 0) {
  if (offset === 1) {
    offset = 0;
  } else {
    offset = offset * limit - 10;
  }

  const client = useClient();
  const data = {
    businessId: businessId,
    limit,
    offset,
  };

  return useQuery(["getBusinessTexts", offset], () =>
    client("getBusinessTexts", { data })
  );
}

function useSendTextMessage() {
  const client = useClient();
  const queryClient = useQueryClient();

  const sendTextMessage = (data) => {
    return client("sendText", { data });
  };

  return useMutation(sendTextMessage, {
    onSuccess: () => {
      alertSuccess("Text message was sent");
    },
    onError: (err) => {
      // Don't show normal error if duplicate.
      if (err.error !== "Duplicate Text") {
        alertError(err.error);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(["getBusinessTexts", 0]);
    },
  });
}

function useDeleteTextMessage() {
  const client = useClient();
  const queryClient = useQueryClient();

  const deleteTextMessage = (data) => client("updateText", { data });

  return useMutation(deleteTextMessage, {
    onSuccess: () => {
      alertSuccess("Text was deleted");
    },
    onSettled: () => {
      queryClient.invalidateQueries(["getBusinessTexts", 0]);
    },
  });
}

export { useTextMessages, useSendTextMessage, useDeleteTextMessage };
