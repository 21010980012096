/* This example requires Tailwind CSS v2.0+ */
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import {
  CursorClickIcon,
  MailOpenIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import Icon from "Components/Icon";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const StatBox = ({
  name,
  stat,
  icon = UsersIcon,
  bgColor = "pelorous",
  iconColor = "white",
  statColor,
  labelColor,
}) => {
  const iconSettings = {
    icon,
  };
  return (
    <dl className="bg-white shadow justify-self-stretch">
      <div className="relative bg-white pt-5 px-4 pb-5 sm:pt-6 sm:px-6 overflow-hidden border-r border-y">
        <dt>
          <div
            className={`absolute bg-gigas-100 p-3 text-white bg-pelorous rounded-md`}
          >
            <iconSettings.icon
              className="h-6 w-6 text-gigas-500"
              aria-hidden="true"
            />
          </div>
          <p
            className={`ml-16 text-sm font-medium text-${labelColor} truncate`}
          >
            {name}
          </p>
        </dt>
        <dd className="ml-16 flex items-baseline">
          <p className={`text-2xl font-semibold text-primary`}>{stat}</p>
        </dd>
      </div>
    </dl>
  );
};

export default StatBox;
