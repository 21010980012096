import { useState } from "react";
import * as auth from "../../authProvider";
import { useNavigate } from "react-router-dom";
import Alert from "../../Components/Alert/Alert";

export const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  let navigate = useNavigate();

  const handleEmail = (event: any) => setEmail(event.target.value);

  const handlePasswordReset = async (email: string) => {
    console.log("reset");
    try {
      const response = await auth.resetPassword({ email });
      setSuccess(
        "We sent an email with a reset password link, it may take a few minutes to arrive. Check your spam folder if you can't find it!"
      );
    } catch (e: any) {
      setError(e.message);
      console.log("e", e.message);
    }
  };

  return success ? (
    <Alert msg={success} background="success" icon="success" text="success" />
  ) : (
    <form
      action="#"
      method="POST"
      className="space-y-6"
      onSubmit={(e) => {
        e.preventDefault();
        handlePasswordReset(email);
      }}
    >
      <div>{error && <Alert msg={error} />}</div>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Email address
        </label>
        <div className="mt-1">
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            onChange={handleEmail}
            required
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-secondary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Submit Password Reset
        </button>
      </div>
    </form>
  );
};
