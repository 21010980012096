import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const AdvertisingNav = () => {
  let loc = useLocation();

  const formatUrl = (url, destination) => {
    const path = url.split("/").pop();
    return url.replace(path, destination);
  };

  const navigation = [
    { name: "Stats", href: formatUrl(loc.pathname, "stats"), current: true },
    {
      name: "Call Tracking",
      href: formatUrl(loc.pathname, "calls"),
      current: false,
    },
    // {
    //   name: "Keyword Searches",
    //   href: formatUrl(loc.pathname, "searches"),
    //   current: false,
    // },
  ];

  return (
    <div className="border-b pl-6">
      <nav className="max-w-7xl -mb-px flex space-x-8">
        {navigation.map((item) => {
          return (
            <NavLink
              key={item.name}
              to={item.href}
              // className="border-transparent text-gray-500 hover:text-secondary hover:border-secondary group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
              className={({ isActive }) =>
                isActive
                  ? "text-reputation border-reputation group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
                  : "border-transparent text-gray-500 hover:text-reputation hover:border-reputation group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
              }
            >
              {item.name}
            </NavLink>
          );
        })}
      </nav>
    </div>
  );
};

export default AdvertisingNav;
