import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { UserIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TopNav = ({ user, logout }) => {
  const userNavigation = [
    { name: "Your Profile", href: "/profile" },
    { name: "Sign out", href: "#", action: logout },
  ];

  const { firstName, lastName } = user.account;
  return (
    <>
      {" "}
      <Menu as="div" className="relative flex-shrink-0">
        <div>
          <Menu.Button className="bg-white rounded-full px-4 flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span className="sr-only">Open user menu</span>
            <UserIcon className="w-5 h-5 mr-2" /> {firstName} {lastName}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            {userNavigation.map((item) => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <a
                    href={item.href}
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700"
                    )}
                    onClick={item.action}
                  >
                    {item.name}
                  </a>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default TopNav;
