import WebStats from "Pages/Stats/WebStats";
import React from "react";

const WebsiteStats = () => {
  return (
    <div>
      <WebStats />
    </div>
  );
};

export default WebsiteStats;
