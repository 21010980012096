import Container from "../Container";
import { useAuth } from "../../context/authContext";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { client } from "../../utils/api-client";
import { alertError, alertSuccess } from "../../utils/alerts";
import { useClient } from "../../context/authContext";
import { useAccount } from "../../api/useAccount";
import ListItem from "../../Modules/ProfileForm/ListItem";
import Fullpagespinner from "../../Components/Loaders/FullPageSpinner";
import { useState } from "react";
import Container3 from "Pages/Container3";
import SubPageHeading from "Components/SubPageHeading";

const Profile = () => {
  const { user } = useAuth();
  const { data, isLoading } = useAccount(user.id);
  const { register, handleSubmit } = useForm();
  const client = useClient();
  const queryClient = useQueryClient();

  const onSubmit = (data) => {
    console.log(data);
    updateAccount.mutate(data);
  };

  const updateAccount = useMutation(
    async (accountData) => {
      const { firstName, lastName, email, password } = accountData;
      const data = {
        accountId: user.account.id,
        firstName,
        lastName,
        email,
        password,
      };
      try {
        let response = await client("updateAccount", { data });
        alertSuccess("Account was updated");
        window.location.assign(window.location);
      } catch (error) {
        alertError(error.error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getAccountById", 0]);
      },
    },
    {
      onError: (error, variables, context) => {
        console.log(error);
      },
    }
  );

  if (isLoading || !data) {
    return <Fullpagespinner />;
  }
  return (
    <Container3 title="Profile">
      <h2 className="text-4xl font-bold ml-8 mb-8 pt-8">Your Profile</h2>

      <div className=" divide-y divide-gray-200">
        <div className="space-y-1"></div>
        <div className="mt-6 px-8">
          <dl>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ListItem
                name="firstName"
                label="First Name"
                data={data[0].firstName}
                register={register}
              />

              <ListItem
                name="lastName"
                label="Last Name"
                data={data[0].lastName}
                register={register}
                required
              />
              <ListItem
                name="email"
                label="Email"
                data={data[0].email}
                register={register}
                required
              />
              <ListItem
                name="password"
                label="Password"
                data="************"
                register={register}
                required
              />
            </form>
          </dl>
        </div>
      </div>
      {/* <form onSubmit={handleSubmit(onSubmit)}>
        <Input label="Frist Name" register={register} required />
        <input placeholder="first name" {...register("firstName")} />
        <input placeholder="last name" {...register("lastName")} />
        <input placeholder="email" {...register("email")} />
        <input
          placeholder="password"
          type="password"
          {...register("password")}
        />
        <button>submit</button>
      </form> */}
    </Container3>
  );
};

export default Profile;
