import { useQuery} from "react-query";
import { useClient } from "../context/authContext";

function useAccount(id) {
  const client = useClient();

  const data = {
    id,
  };

  return useQuery("getAccountById", () => client("getAccountById", { data }));
}

export { useAccount };
