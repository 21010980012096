import { TicketIcon } from "@heroicons/react/outline";
import parse from "html-react-parser";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";

const Comment = ({ comment }) => {
  return (
    <div className="rounded-md bg-blue-50 border border-blue-300 p-4 mt-4">
      <div className="flex items-center">
        <div className="font- flex-1">{comment.author.name}</div>
        <div className="text-sm text-gray-500">{comment.created_at}</div>
      </div>
      {parse(comment.html_body)}
    </div>
  );
};

export default function TicketDetail({ ticket, comments }) {
  return (
    <div>
      <div className="p-8 flex">
        <MdArrowBack className="h-6 w-6 text-blue-600 mr-2" />
        <Link
          to="/support/tickets"
          className="text-blue-600 hover:text-blue-800 hover:underline"
        >
          Back to all tickets
        </Link>
      </div>
      <h2 className="text-xl font-bold  pl-8">{ticket.subject}</h2>
      <div className="flex items-center border-b text-sm border-gray-200 py-3 pl-8">
        <TicketIcon className="h-6 w-6 mr-2  text-orange-400" />
        <div>{ticket.id}</div>
        <div className="mx-2">|</div>
        <div>{dateFormat(ticket.created_at, "mm-dd-yy h:MM TT")}</div>
      </div>

      <div className="mt-12 mx-8 max-w-5xl">
        <div className="rounded-md bg-gray-50 border border-gray-300 p-4 mt-4">
          <div className="font-bold">Description</div>
          {ticket.description}
        </div>
        {comments.slice(1).map((comment) => (
          <Comment key={comment.id} comment={comment} />
        ))}
      </div>
    </div>
  );
}
