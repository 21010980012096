import PhoneNumber from "Components/PhoneNumber/PhoneNumber";
import { formatDateAndTime } from "utils/dateHelpers";
import {
  ChatIcon,
  PhoneIncomingIcon,
  PhoneMissedCallIcon,
  XCircleIcon,
} from "@heroicons/react/solid";

const FormatStatus = ({ status }) => {
  switch (status) {
    case "XFER_LEG":
      return (
        <span className="inline-flex items-center my-3 text-sm uppercase">
          <PhoneIncomingIcon
            className="self-center flex-shrink-0 h-5 w-5 text-green-500 mr-2"
            aria-hidden="true"
          />
          Transfered
        </span>
      );
    case "ABANDONED":
      return (
        <span className="inline-flex items-center my-3 text-sm uppercase">
          <XCircleIcon
            className="self-center flex-shrink-0 h-5 w-5 text-red-500 mr-2"
            aria-hidden="true"
          />
          Abandoned
        </span>
      );
    case "LA":
      return (
        <span className="inline-flex items-center my-3 text-sm uppercase">
          <ChatIcon
            className="self-center flex-shrink-0 h-5 w-5 text-blue-500 mr-2"
            aria-hidden="true"
          />
          Live Answer
        </span>
      );
    case "NO_ANS":
      return (
        <span className="inline-flex items-center my-3 text-sm uppercase">
          <PhoneMissedCallIcon
            className="self-center flex-shrink-0 h-5 w-5 text-orange-500 mr-2"
            aria-hidden="true"
          />
          No Answer
        </span>
      );
    default:
      return <span></span>;
  }
};

const CallTrackingTable = ({ calls }) => {
  return (
    <>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Contact Number
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Transfered Number
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Audio
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {calls.map((call) => {
                    return (
                      <tr key={call.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-secondary">
                          {formatDateAndTime(call.created)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <PhoneNumber phoneNumber={call.contact.homePhone} />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <PhoneNumber phoneNumber={call.records[0].toNumber} />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <FormatStatus status={call.records[0].result} />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <audio
                            controls
                            src={
                              call?.records[1]?.recordings?.[0]?.mp3Url || null
                            }
                          >
                            Your browser does not support the <code>audio</code>{" "}
                            element.
                          </audio>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallTrackingTable;
