import StatBox from "../../Modules/Stats/StatBox/StatBox";

import { useGoogleAnalyticsGA4 } from "../../api/useGoogleGA4";
import { useState, useContext } from "react";
import PieGraph from "../../Modules/Stats/PieGraph/PieGraph";
import Fullpagespinner from "../../Components/Loaders/FullPageSpinner";
import Alert from "../../Components/Alert/Alert";
import StatTable from "../../Modules/Stats/StatTable/StatTable";
import LineGraph from "../../Modules/Stats/LineGraph/LineGraph";
import { ChartBarIcon } from "@heroicons/react/outline";
import { useParams } from "react-router-dom";
import { LocationContext } from "context/locationContext";
import {
  formatPieData,
  formatStatTable,
  formatWebVists,
} from "../../utils/dataHelpers";
import ErrorBoundary from "utils/errorBoundry";
import DateRange from "Components/DateRange";
import ReportHeading from "Components/ReportHeading";

const WebStats = () => {
  const location = useContext(LocationContext);
  let { id } = useParams();
  const viewId = location?.websites[0].googleAnalyticsProfileId;
  const [startDate, setStartDate] = useState("30daysAgo");
  const [endDate, setEndDate] = useState("today");

  const updateDateRange = (start) => {
    setStartDate(start);
    // setEndDate(end);
  };

  const ga_options = [
    {
      label: "All Users",
      dimensions: [],
      metrics: [{ name: "totalUsers" }],
    },
    {
      label: "New Users",
      dimensions: [],
      metrics: [{ name: "newUsers" }],
    },
    {
      label: "Bounce Rate",
      dimensions: [],
      metrics: [{ name: "bounceRate" }],
    },
    {
      label: "Views By Source",
      dimensions: [{ name: "sessionMedium" }],
      metrics: [{ name: "sessions" }],
    },
    {
      label: "Most Viewed Pages",
      dimensions: [{ name: "pagePathPlusQueryString" }],
      metrics: [{ name: "screenPageViews" }],
    },
  ];

  const ga_options2 = [
    {
      label: "Views By Device",
      dimensions: [{ name: "deviceCategory" }],
      metrics: [{ name: "sessions" }],
    },
    {
      label: "Page Views",
      dimensions: [{ name: "date" }],
      metrics: [{ name: "screenPageViews" }],
      orderBys: [
        {
          desc: false,
          dimension: { dimensionName: "date", orderType: "NUMERIC" },
        },
      ],
    },
  ];

  const { data, isLoading, isError } = useGoogleAnalyticsGA4(
    viewId,
    startDate,
    endDate,
    ga_options,
    "analytics"
  );

  const { data: data2, isError: data2Error } = useGoogleAnalyticsGA4(
    viewId,
    startDate,
    endDate,
    ga_options2,
    "analytics2"
  );

  const forrmatedWebVisits = formatWebVists(data2?.reports[1]?.rows);

  console.log("forrmatedWebVisits", forrmatedWebVisits);
  if (isError) {
    return <Alert msg="Oops, something went wrong. Try refreshing the page" />;
  }

  const handleDateChange = (e) => updateDateRange(e.target.value);

  return (
    <div>
      <ReportHeading
        title="Overview"
        childComp={<DateRange onChange={handleDateChange} />}
      />
      {!isLoading ? (
        <div className="grid grid-cols-1 md:grid-cols-3">
          <div>
            <ErrorBoundary>
              <StatBox
                name="Total Visitors"
                stat={data?.reports[0].rows[0].metricValues[0].value}
                bgColor="advertising-100"
                iconColor="advertising-300"
                statColor="advertising-300"
                labelColor="shadow"
              />
            </ErrorBoundary>
          </div>
          <div>
            <ErrorBoundary>
              <StatBox
                name="New Visitors"
                stat={Math.round(
                  data?.reports[1].rows[0].metricValues[0].value
                )}
                icon={ChartBarIcon}
                bgColor="website-100"
                iconColor="website-300"
                statColor="website-300"
                labelColor="shadow"
              />
            </ErrorBoundary>
          </div>
          <div>
            <ErrorBoundary>
              <StatBox
                name="Bounce Rate"
                stat={
                  Math.round(
                    data?.reports[2].rows[0].metricValues[0].value * 100
                  ) + "%"
                }
                bgColor="advertising-100"
                iconColor="search-300"
                statColor="search-300"
                labelColor="shadow"
              />
            </ErrorBoundary>
          </div>
          <div className="md:col-span-3 lg:col-span-1 border-b lg:border-r">
            <ErrorBoundary>
              <PieGraph
                data={formatPieData(data2?.reports[0]?.rows)}
                name="Views by Device"
              />
            </ErrorBoundary>
          </div>
          <div className="md:col-span-3 lg:col-span-1 border-b">
            <ErrorBoundary>
              <PieGraph
                data={formatPieData(data?.reports[3]?.rows)}
                name="Views By Source"
              />
            </ErrorBoundary>
          </div>
          <div className="md:col-span-3 lg:col-span-1 lg:row-span-3 border-b border-l">
            <ErrorBoundary>
              <StatTable
                data={formatStatTable(data?.reports[4]?.rows)}
                name="Pages Viewed"
              />
            </ErrorBoundary>
          </div>
          <div className="md:col-span-3 border-b lg:col-span-2">
            <ErrorBoundary>
              <LineGraph
                data={forrmatedWebVisits}
                name="Page Views"
                legendXLabel=""
                legendYLabel="number of views"
              />
            </ErrorBoundary>
          </div>
        </div>
      ) : (
        <Fullpagespinner />
      )}
    </div>
  );
};

export default WebStats;
