import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useBusinessData, useUpdateBusiness } from "api/useBusinessData";
import DevicePreview from "./devicePreview";
import Button from "Components/Button";

const TextMessageForm = ({ formData, setFormData, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      <form className="col-span-2 p-8" onSubmit={handleSubmit(onSubmit)}>
        <label
          htmlFor="project-name"
          className="block text-sm font-medium text-gray-700"
        >
          Text Message (100 characters max)
        </label>
        <div className="mt-2">
          <textarea
            defaultValue={formData?.messageText}
            {...register("messageText", {
              required: true,
              maxLength: 100,
              onChange: (e) => {
                setFormData({
                  ...formData,
                  messageText: e.target.value,
                });
                console.log(e.target.value);
              },
            })}
            className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md border p-2 h-28"
          />
        </div>
        {errors.messageText && errors.messageText.type === "maxLength" && (
          <p>Must be 100 characters or less</p>
        )}
        {errors.messageText && errors.messageText.type === "required" && (
          <p>this field is required</p>
        )}

        <div className="my-4">
          <Button label="Submit" color="secondary" loading={false} />
        </div>
      </form>
      <div className="col-span-4 p-8">
        <DevicePreview device="phone" text={formData} />
      </div>
    </>
  );
};

export default TextMessageForm;
