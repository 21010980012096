import React from "react";
import {
  AiOutlineStar,
  AiOutlineBarChart,
  AiOutlineGoogle,
} from "react-icons/ai";
import { CgWebsite } from "react-icons/cg";
import { MdBarChart, MdStar } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
const SubNavigation = ({ items }) => {
  console.log(items);

  if (!items) return null;

  return (
    <div className="border-b pl-6">
      <nav className="max-w-7xl -mb-px flex space-x-8">
        {items.map((item, index) => {
          return (
            <NavLink
              key={index}
              to={item.to}
              className={({ isActive }) =>
                isActive
                  ? "text-secondary border-secondary group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
                  : "border-transparent text-gray-500 hover:text-reputation hover:border-reputation group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
              }
            >
              {item.label}
            </NavLink>
          );
        })}
      </nav>
    </div>
  );
};

export default SubNavigation;
