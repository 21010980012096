import { ResponsiveLine } from "@nivo/line";
import sampleData from "./sample.json";

const LineGraph = ({ data, name, yLabel = "", spacing = "every 4 days" }) => {
  if (!data) return null;

  // hey chris! its you from the past. you should take the data and format the date like yyyy-mm-dd and test out the axis bottom tick values
  return (
    <div className=" bg-white justify-self-stretch">
      <div className="h-96 relative bg-white pt-5 pb-8 px-4 sm:pt-6 sm:px-6 overflow-hidden">
        <p className="text-lg font-medium pb-4 text-gray-900 truncate text-center">
          {name}
        </p>
        <ResponsiveLine
          data={data}
          margin={{ top: 10, right: 10, bottom: 100, left: 60 }}
          xScale={{ type: "time", format: "%Y-%m-%d", precision: "day" }}
          yScale={{
            type: "linear",
            // min: "auto",
            // max: "auto",
            stacked: false,
            // reverse: false,
          }}
          yFormat=" >-.2f"
          d
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: "%b %d",
            tickValues: spacing,
            legend: "time scale",
            legendOffset: -12,
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yLabel,
            legendOffset: -40,
            legendPosition: "middle",
          }}
          pointSize={8}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          //curve="catmullRom"
          colors={["#F9AD45", "#762a83", "#51b8aa"]}
          enableSlices={"x"}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 50,
              translateY: 50,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 120,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};

export default LineGraph;
