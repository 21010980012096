import React from "react";
import iphone from "images/phone-bg.png";
import email from "images/email.png";
import desktop from "images/desktop.png";

const DevicePreview = ({ device, text }) => {
  if (device === "phone") {
    return (
      <div
        className="h-full relative"
        style={{
          backgroundImage: `url(${iphone})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top center",
          minHeight: "500px",
          width: "400px",
        }}
      >
        <div className="bg-blue-200 p-2 text-xs rounded-lg inline-block ml-20 mt-4 absolute top-12 left-4 w-48">
          {text?.messageText}
        </div>
      </div>
    );
  }

  if (device === "email") {
    return (
      <div
        className="h-full relative pt-12 pl-24"
        style={{
          backgroundImage: `url(${email})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          minHeight: "500px",
        }}
      >
        <div className="p-2 text-xs rounded-lg w-80 ">{text?.emailText}</div>
      </div>
    );
  }

  if (device === "desktop") {
    return (
      <div
        className="h-full pt-28 pl-6 mx-12"
        style={{
          backgroundImage: `url(${desktop})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          minHeight: "500px",
        }}
      >
        <div className="grid gap-4 w-80">
          <div className="p-4 text-sm">{text?.landingText}</div>
          <div className="p-4 text-xs rounded-lg inline">
            {text?.landingLinksText}
          </div>
          <button className="bg-blue-600 text-white p-2 text-xs rounded-lg flex justify-center m-4">
            {text?.landingPositiveButtonText}
          </button>

          <div className="p-4 text-xs">{text?.landingNegativeText}</div>
        </div>
      </div>
    );
  }
};

export default DevicePreview;
