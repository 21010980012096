import { useQuery } from "react-query";
import { useClient } from "../context/authContext";

export default function useAppdata(accountId) {
  const client = useClient();
  const data = {
    accountId,
  };
  return useQuery("appData", () => client("getAppData2", { data }));
}
