import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { alertSuccess, alertError } from "utils/alerts";
const api = axios.create({
  baseURL: "https://goldn.zendesk.com/api/v2",
  headers: {
    Authorization: "Basic Y2VsaWFzQGdldGdvbGRuLmNvbTpwcmVjN3NvZy5QU0FMKnRyYWg=",
    "Content-Type": "application/json",
  },
});

function useGetTickets(name, pageSize = 10, currentPage = 1) {
  const query = {
    query: `requester:${name}`,

    per_page: pageSize,
    page: currentPage,
  };
  const getTickets = async () => {
    const response = await api.get(`/search`, { params: query });

    return response.data;
  };

  return useQuery(["getTickets", currentPage], () => getTickets());
}

function useGetTicketById(id) {
  const getTicketById = async () => {
    let ticket = await api.get(`/tickets/${id}`);
    let comments = await api.get(`/tickets/${id}/comments?include=users`);
    ticket = ticket.data;
    comments = comments.data;

    const getUserName = (comment) => {
      const user = comments.users.find((user) => user.id === comment.author_id);
      comment.author = user;
    };

    comments.comments.map((comment) => getUserName(comment));
    ticket.comments = comments.comments;

    return ticket;
  };
  return useQuery(["getTicketById", id], () => getTicketById());
}

function useCreateTicket() {
  const queryClient = useQueryClient();

  const createTicket = async (formData) => {
    console.log(
      "🚀 ~ file: useZendesk.tsx:43 ~ createTicket ~ formData:",
      formData
    );

    return await api.post(`/tickets`, formData);
  };

  return useMutation(createTicket, {
    onSuccess: () => {
      alertSuccess("Ticket was created");
    },
    onError: (err) => {
      alertError(err);
    },
    onSettled: () => {
      console.log("settled");
      queryClient.invalidateQueries(["getTickets", 0]);
    },
  });
}

export { useGetTickets, useGetTicketById, useCreateTicket };

// b48dvmw5EMcgkcB1Qo3o3uR3aMp0DH1iOEugFUXm
