import { Routes, Route } from "react-router-dom";
import React from "react";
import Profile from "./Pages/Account/Profile";
import Dashboard from "./Pages/Dashboard";
import Locations from "./Pages/Locations";
import Reputation from "./Pages/Reputation/Reputation";
import Search from "./Pages/Search/Search";
import PPC from "./Pages/PPC/PPC";
import { Toaster } from "react-hot-toast";
import ReputationStats from "./Modules/Reputation/ReputationStats/ReputationStats";
import ReputationSMS from "./Modules/Reputation/ReputationSMS";
import ReputationEmails from "./Modules/Reputation/ReputationEmails";
import ReputationReviews from "./Modules/Reputation/ReputationReviews";
import ReputationSettings from "./Modules/Reputation/ReputationSettings/ReputationSettings";
import Website from "Pages/Website/Website";
import AdvertisingCalls from "Modules/Advertising/AdvertistingCalls";
import WebsiteStats from "Modules/Website/WebsiteStats";
import AdvertisingSearches from "Modules/Advertising/AdvertisingSearches";
import AdvertisingStats from "Modules/Advertising/AdvertisingStats";
import LocationContainer from "Pages/LocationContainer";
import Support from "Pages/Support/Support";
import Tickets from "Pages/Support/Tickets";
import TicketDetails from "Pages/Support/TicketDetails";
import CreateTicket from "Pages/Support/CreateTicket";
import Contact from "Pages/Support/Contact";
const AuthenticatedApp = () => {
  return (
    <div>
      {/* Component for custom notifications*/}
      <Toaster />

      <Routes>
        <Route path="/" element={<Locations />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/locations" element={<Locations />} />
        <Route path="/locations/:id" element={<LocationContainer />}>
          <Route path="reputation" element={<Reputation />}>
            <Route path="stats" element={<ReputationStats />} />
            <Route path="texts" element={<ReputationSMS />} />
            <Route path="emails" element={<ReputationEmails />} />
            <Route path="reviews" element={<ReputationReviews />} />
            <Route path="settings" element={<ReputationSettings />} />
          </Route>
          <Route path="website" element={<Website />}>
            <Route path="stats" element={<WebsiteStats />} />
          </Route>

          <Route path="search" element={<Search />} />
          <Route path="advertising" element={<PPC />}>
            <Route path="calls" element={<AdvertisingCalls />} />
            <Route path="stats" element={<AdvertisingStats />} />
            <Route path="searches" element={<AdvertisingSearches />} />
          </Route>
        </Route>
        <Route path="/support" element={<Support />}>
          <Route path="contact" element={<Contact />} />
          <Route path="tickets" element={<Tickets />} />
          <Route path="tickets/:id" element={<TicketDetails />} />
          <Route path="create-ticket" element={<CreateTicket />} />
        </Route>
      </Routes>
    </div>
  );
};

export default AuthenticatedApp;
