// pretend this is firebase, netlify, or auth0's code.
// you shouldn't have to implement something like this in your own app

type Login = {
  email: string;
  password: string;
};

type User = {
  email: string;
  id: string;
  token: string;
  userRole: string;
  account: {
    token: string;
  };
};

interface Reset {
  email: string;
}

interface NewPassword {
  password: string;
  guid: string | undefined;
  accountsId: string;
}

// const localStorageKey = '__auth_provider_token__'
const localStorageKey = "account";

async function getToken() {
  // if we were a real auth provider, this is where we would make a request
  // to retrieve the user's token. (It's a bit more complicated than that...
  // but you're probably not an auth provider so you don't need to worry about it).
  return window.localStorage.getItem(localStorageKey);
}

function handleUserResponse(user: User) {
  window.localStorage.setItem(localStorageKey, JSON.stringify(user));
  // window.localStorage.setItem('account', JSON.stringify(user))
  return user;
}

function login(login: Login) {
  return client("login", login).then(handleUserResponse);
}

function resetPassword(reset: Reset) {
  return client("resetpassword", reset).then((res) => console.log(res));
}

function setNewPassword(newPassword: NewPassword) {
  const response = client("setpassword", newPassword).then((res) => res);
  return response;
}

// TODO: make the table url a variable
async function verifyGuid(guid: string) {
  const response = await fetch(
    `https://app.mylocalbeacon.com/tables/accounts_reset/${guid}`
  );
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const result = await response.json();
  return result;
}

async function logout() {
  window.localStorage.removeItem(localStorageKey);
  window.localStorage.removeItem("account");
  window.location.replace("/");
}

// an auth provider wouldn't use your client, they'd have their own
// so that's why we're not just re-using the client
const authURL = process.env.REACT_APP_AUTH_URL;

async function client(endpoint: string, data: object) {
  const config = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  };

  return window
    .fetch(`${authURL}/${endpoint}`, config)
    .then(async (response) => {
      console.log("response", response);
      if (!response.ok) {
        const message = await response.text();
        throw new Error(message);
      }
      const data = await response.json();
      // return data;
      if (response.ok) {
        return data;
      } else {
        console.log("err", data);
        return Promise.reject(data);
      }
    });
}

export {
  getToken,
  login,
  logout,
  resetPassword,
  localStorageKey,
  verifyGuid,
  setNewPassword,
};
