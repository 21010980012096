import Lottie from "lottie-react";
import loginAnimation from "lotties/loginAnimation.json";

const LoginAnimation = () => {
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: loginAnimation,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  return (
    <div className="h-screen flex">
      <div className="m-auto">
        <Lottie
          animationData={loginAnimation}
          height={800}
          width={800}
          style={{ margin: "0", padding: "3rem" }}
        />
        <div className="ml-8">
          <div className="flex">
            <span className="uppercase text-white text-5xl bg-blue-800 p-4">
              Smart
            </span>
          </div>

          <div className="flex">
            <span className="uppercase text-white text-5xl bg-blue-800 p-4">
              Marketing
            </span>
          </div>

          <div className="flex">
            <span className="uppercase text-white text-5xl bg-blue-800 p-4">
              Platform by <span className="font-bold">GOLDN</span>
            </span>
          </div>
        </div>
        <div className="ml-12 text-xl p-4 text-secondary">
          Take Control of Your Online Brand
        </div>
      </div>
    </div>
  );
};

export default LoginAnimation;
