import React from "react";
import LocationCard from "./LocationCard";
import LocationListItem from "./LocationListItem";

const LocationListAlt = ({ locations }) => {
  return (
    <div className="border-t">
      {locations.map((location) => (
        <LocationListItem key={location.id} location={location} />
      ))}
    </div>
  );
};

export default LocationListAlt;
