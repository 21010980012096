import { useQuery, useMutation, useQueryClient } from "react-query";
import { client } from "utils/api-client";
import { useClient } from "../context/authContext";
import { alertSuccess, alertError } from "utils/alerts";
function useEmailMessages(accountsId, limit = 10, offset = 0) {
  if (offset === 1) {
    offset = 0;
  } else {
    offset = offset * limit - 10;
  }

  const client = useClient();
  const data = {
    accountsId,
    limit,
    offset,
  };

  return useQuery(["getBusinessEmails", offset], () =>
    client("getBusinessEmails", { data })
  );
}

function useSendEmailMessage() {
  const queryClient = useQueryClient();
  const client = useClient();
  const sendEmail = (data) => {
    return client("sendTransactionalEmails", { data });
  };
  return useMutation(sendEmail, {
    onSuccess: () => {
      alertSuccess("Email message was sent");
    },
    onError: (err) => {
      alertError(err.error);
    },
    onSettled: () => {
      queryClient.invalidateQueries("getBusinessEmails");
    },
  });
}

function useDeleteEmailMessage() {
  const client = useClient();
  const queryClient = useQueryClient();

  const deleteEmailMessage = (data) => client("updateEmail", { data });

  return useMutation(deleteEmailMessage, {
    onSuccess: () => {
      alertSuccess("Email was deleted");
    },
    onSettled: () => {
      queryClient.invalidateQueries("getBusinessEmails");
    },
  });
}

export { useEmailMessages, useDeleteEmailMessage, useSendEmailMessage };
