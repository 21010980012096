import React from "react";
import SubPageHeading from "Components/SubPageHeading";
import CreateTicketForm from "Pages/Support/components/CreateTicketForm";

const CreateTicket = () => {
  return (
    <>
      <div className="m-8">
        <SubPageHeading
          title="Get Help Fast"
          body="Create a ticket to get help from our support team"
        />
        <div className="mt-9 max-w-3xl">
          <CreateTicketForm />
        </div>
      </div>
    </>
  );
};

export default CreateTicket;
