import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "context/authContext";
import Button from "Components/Button";
import { useSendEmailMessage } from "api/useEmailMessages";
import ReportHeading from "Components/ReportHeading";

const EmailForm = ({ location }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ criteriaMode: "all" });
  const { user } = useAuth();
  const { mutate: sendEmail, isLoading, isSuccess } = useSendEmailMessage();
  const onSubmit = (formData) => {
    const globalMergeVars = [
      {
        name: "reviewlandingurl",
        content: "https://review-us.getgoldn.com/" + location.id,
      },
    ];

    if (location.product_reviewGeneration.emailText) {
      globalMergeVars.push({
        name: "emailcontent",
        content: location.product_reviewGeneration.emailText,
      });
    }
    const email = {
      accountsId: user.account.id,
      businessId: location.id,
      emailType: "consumer-review-generation",
      fromName: location.name,
      subject: `Leave Feedback for ${location.name}`,
      message: null,
      insertBefore: true,
      list: [
        {
          name: "",
          email: formData.email,
        },
      ],
      globalMergeVars,
    };

    sendEmail(email);
  };

  useEffect(() => {
    reset({
      email: "",
    });
  }, [isSuccess, reset]);

  return (
    <ReportHeading
      title="Email"
      description="Simply enter an email address and click “Send Email” to send an
    automated message."
      childComp={
        <form
          className=""
          onSubmit={handleSubmit(onSubmit)}
          data-testid="email-form"
        >
          <div className="w-full h-8 pb-2">
            <p className="text-sm text-red-600">
              {errors.email && (
                <span className="-mt-8">A valid email is required</span>
              )}
            </p>
          </div>
          <div className="sm:flex">
            <div className="w-full sm:max-w-xs ">
              <label htmlFor="email" className="sr-only">
                Email Address
              </label>
              <input
                type="email"
                data-testid="email-input"
                className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md border p-2"
                placeholder="email@domain.com"
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
            </div>
            <Button
              label="Send Email"
              color="primary"
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        </form>
      }
    />
  );
};

export default EmailForm;
