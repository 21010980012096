import Input from "Components/Input";
import Textarea from "Components/Textarea";
import Select from "Components/Select";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useCreateTicket } from "api/useZendesk";
import { Button } from "Components/Button";
const categories = [
  { value: "support", label: "Support" },
  { value: "Billing", label: "Billing" },
  { value: "bug", label: "Bug" },
  { value: "sales", label: "Sales" },
  { value: "setup", label: "Setup" },
  { value: "feature request", label: "Feature Request" },
];

const priorities = [
  { value: "low", label: "Low" },
  { value: "normal", label: "Normal" },
  { value: "high", label: "High" },
  { value: "urgent", label: "Urgent" },
];

const handleSelectChange = (value) => {
  console.log(value);
};

const CreateTicketForm = () => {
  const {
    mutate: createTicket,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useCreateTicket();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const defaultFormValues = {
    ticket: {
      comment: {
        body: "",
      },
      priority: "",
      metadata: {
        category: "",
      },
      subject: "",
      requester: "djacobson+shapiro@getgoldn.com",
    },
  };

  const onSubmit = (formData) => {
    console.log(formData);
    defaultFormValues.ticket.comment.body = formData.description;
    defaultFormValues.ticket.priority = formData.priority;
    defaultFormValues.ticket.category = formData.metadata.category;
    defaultFormValues.ticket.subject = formData.subject;
    console.log(defaultFormValues);
    createTicket(defaultFormValues);
  };

  return (
    <div>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="mb-4">
          <Input
            label="Subject"
            placeholder="subject..."
            {...register("subject")}
          />
        </div>
        <div className="mb-4">
          <Select
            label="Category"
            options={categories}
            onChange={handleSelectChange}
            {...register("category")}
          />
        </div>
        <div className="mb-4">
          <Select
            label="Priority"
            options={priorities}
            onChange={handleSelectChange}
            {...register("priority")}
          />
        </div>
        <div className="mb-4">
          <Textarea
            label="Description"
            placeholder="Description..."
            {...register("description")}
          />
        </div>
        <button
          type="submit"
          className="rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CreateTicketForm;
