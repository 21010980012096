import AdminDashboard from "Pages/Admin/AdminDashboard";
import { Toaster } from "react-hot-toast";
import { Routes, Route } from "react-router-dom";

const AdminApp = () => {
  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/" element={<AdminDashboard />} />
      </Routes>
    </>
  );
};

export default AdminApp;
