import { useParams } from "react-router-dom";
import { useBusinessData } from "api/useBusinessData";
import { useEffect, useState } from "react";
import getBusinessesProducts from "utils/getBusinessesProducts";
import React from "react";
import { LocationContext } from "context/locationContext";
import Loader from "Components/Loaders/Loader";
import GoogleMapContainer from "Components/GoogleMap";
import ProductLinks from "Modules/LocationList/ProductLinks";

const LocationDetails = () => {
  let { id }: any = useParams();
  const { data: location, isLoading } = useBusinessData(id);
  const products = getBusinessesProducts(location?.products);

  if (isLoading) <Loader />;

  return (
    <>
      <div className="py-8 bg-white flex flex-col lg:flex-row">
        <div className="w-1/2 pl-8">
          <h2 className="text-4xl font-bold font-Poppins text-primary border-b pt-1 pb-8">
            {location?.commonName || location?.name}
          </h2>
          <div className="border-b py-8 mb-4">
            <h4>{location?.address}</h4>
            <h4>
              {location?.city}, {location?.state}
            </h4>
          </div>
          {products && <ProductLinks location={location} products={products} />}
        </div>
        <div className="rounded-xl overflow-hidden">
          <GoogleMapContainer lat={location?.lat} lng={location?.lon} />
        </div>
      </div>
    </>
  );
};

export default LocationDetails;
