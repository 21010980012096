const Icon = ({ name, w = "7", h = "7", color, border = "round" }) => {
  const action = { icon: name };
  return (
    <div
      className={`bg-${color}-100 p-2 flex align-middle justify-center mx-auto rounded-full max-w-fit`}
    >
      <action.icon
        className={`text-${color}-500  w-${w} h-${h}`}
        aria-hidden="true"
      />
    </div>
  );
};

export default Icon;
