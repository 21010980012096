import { useQuery } from "react-query";
import { useClient } from "../context/authContext";

export default function useReviewStats(businessId) {
  const client = useClient();

  return useQuery(["getReviewStats", `reviewStats-${businessId}`], () => {
    return client(`businesses/${businessId}/review-generation-analytics`);
  });
}
