import { useState } from "react";
import { NavLink } from "react-router-dom";
import getBusinessesProducts from "utils/getBusinessesProducts";
import { AiOutlineStar } from "react-icons/ai";
import { MdAttachMoney } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

const ProductNav = ({ products, locationId }) => {
  let location = useLocation();
  // console.log("location", location.pathname.includes("/reputation/"));
  products = getBusinessesProducts(products);
  function renderIcons(name) {
    switch (name) {
      case "Website Analytics":
        return <CgWebsite className="w-6 h-6" />;
      case "Google Adwords":
        return <MdAttachMoney className="w-6 h-6" />;
      case "Review Generation":
        return <AiOutlineStar className="w-6 h-6" />;
      case "SEO Report":
        return <AiOutlineStar className="w-6 h-6" />;
      default:
        return null;
    }
  }
  return (
    <nav className="grid grid-cols-2  lg:grid-cols-4 border-y">
      {products.map((product: any, index) => {
        if (!product) return null;
        return (
          <NavLink
            key={index}
            to={`/locations/${locationId}/${product.path}`}
            // className={({ isActive }) =>
            //   isActive && product.label === "Search Marketing"
            //     ? `flex bg-search-300 text-white`
            //     : isActive && location.pathname.includes("/reputation/")
            //     ? `flex bg-reputation-300 text-white`
            //     : isActive && product.label === "Website"
            //     ? `flex bg-website-300 text-white`
            //     : isActive && product.label === "Paid Advertising"
            //     ? `flex bg-advertising-300 text-white`
            //     : "text-gray-800 hover:bg-gray-100 border-r border-y"
            // }
          >
            <div
              className="flex p-4 border-r"
              // className={classNames(
              //   product.label === "Review Generation" &&
              //     location.pathname.includes("/reputation")
              //     ? "bg-reputation-300  hover:bg-reputation-300"
              //     : " hover:bg-gray-50",
              //   product.label === "SEO Report" &&
              //     location.pathname.includes("/search")
              //     ? "bg-search-300  hover:bg-search-300"
              //     : "hover:bg-gray-50",
              //   product.label === "Google Adwords" &&
              //     location.pathname.includes("/advertising")
              //     ? "bg-advertising-300  hover:bg-advertising-300"
              //     : "hover:bg-gray-50",
              //   product.label === "Website Analytics" &&
              //     location.pathname.includes("/website")
              //     ? "bg-website-300  hover:bg-website-300"
              //     : "hover:bg-gray-50",
              //   "flex p-4 border-r"
              // )}
            >
              <div className="flex-shrink-0 flex items-center justify-center w-10 h-10 text-sm font-medium rounded text-white bg-secondary">
                {renderIcons(product.label)}
              </div>
              <div className="flex-1 flex items-center justify-between truncate">
                <div className="flex-1 px-4 text-xs">
                  <span className="uppercase font-bold text-sm text-website-300">
                    {product.label}
                  </span>
                  <p className="text-shadow">Your website stats and visitors</p>
                </div>
              </div>
            </div>
          </NavLink>
        );
      })}
    </nav>
  );
};

export default ProductNav;
