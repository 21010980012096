import { useMutation } from "react-query";
import { useClient } from "../../context/authContext";
import { alertSuccess, alertError } from "utils/alerts";

export default function usePasswordReset() {
  const client = useClient();

  const sendPasswordReset = (email) => {
    const data = {
      email,
    };
    return client("resetpassword", { data });
  };

  const reset = useMutation(sendPasswordReset, {
    onSuccess: () => {
      console.log("success pw");
      alertSuccess("Password Reset was sent");
    },
  });

  return reset;
}
