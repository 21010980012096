import { useQuery } from "react-query";
import { useClient } from "../context/authContext";

function useReportIds(campaignId) {
  const client = useClient();

  const getSearchRankReports = async () => {
    if (campaignId) {
      const data = {
        campaignId,
      };
      const res = await client("getSearchRankReportHistory", { data });
      return res;
    }
  };

  const reportIDs = useQuery(["reportIds", campaignId], getSearchRankReports);

  return reportIDs;
}

function useSEOReports(campaignId, currentReportId, previousReportId) {
  const client = useClient();

  const getSearchRankReports = async () => {
    if (campaignId) {
      const data = {
        campaignId,
      };
      const res = await client("getSearchRankReportHistory", { data });
      return res;
    }
  };
  const { data: reportIDs, isLoading: idsLoading } = useQuery(
    ["reportIds", campaignId],
    getSearchRankReports
  );

  const searchRankReport = useQuery(
    ["getSearchRankReport", { previousReportId, currentReportId }],
    () => {
      const data = {
        campaignId,
        campaignHistoryId:
          currentReportId || reportIDs?.[0].campaign_history_id,
        previousCampaignHistoryId:
          previousReportId || reportIDs?.[1].campaign_history_id,
      };

      return client("getSearchRankReport", { data });
    },
    {
      // The query will not execute until the userId exists
      enabled: !!reportIDs,
    }
  );

  const res = {
    reportIDs,
    idsLoading,
    searchRankReport,
  };
  console.log(res);
  return res;
}

export { useSEOReports, useReportIds };
