import React from "react";
import Login from "./Pages/Account/Login";
import { Routes, Route } from "react-router-dom";
import ForgotPassword from "Pages/Account/ForgotPassword";
import ResetPassword from "Pages/Account/ResetPassword";
import NotFound404 from "Components/404/404";
const Unauthenticatedapp = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/login/reset/:token" element={<ResetPassword />} />
      <Route path="/login/*" element={<NotFound404 />} />
    </Routes>
  );
};

export default Unauthenticatedapp;
