// Social Icons for Reputation table

import {
  FaYelp,
  FaGoogle,
  FaFacebookF,
  FaTwitter,
  FaList,
  FaHeart,
} from "react-icons/fa";

const SocialLabel = ({ sites }) => {
  const clicked = sites.filter((x) => x.type === "click");
  if (clicked.length === 0) {
    return (
      <span className="px-2 inline-flex text-xs leading-5 font-semibold text-gray-300 rounded-full">
        0 Clicks
      </span>
    );
  }
  if (sites.length === 0)
    return <span className="text-gray-300 text-xs">0 Clicks</span>;
  const countSites = sites
    .filter((x) => x.site !== undefined)
    .map((x) => x.site)
    .reduce((allSites, site) => {
      if (site in allSites) {
        allSites[site]++;
      } else {
        allSites[site] = 1;
      }
      return allSites;
    }, {});

  const filteredSites = Object.keys(countSites);

  // const filteredSites = [
  //   "google",
  //   "facebook",
  //   "twitter",
  //   "yelp",
  //   "angieslist",
  //   "healthgrades",
  // ];

  const final = filteredSites.map((site, index) => {
    if (site === "google") {
      return (
        <div
          key={index}
          className="bg-red-100 p-2 rounded-full w-7 h-7 flex mr-2"
        >
          <FaGoogle className="text-red-500" data-testid="google-icon" />
        </div>
      );
    }

    if (site === "facebook") {
      return (
        <div
          key={index}
          className="bg-blue-100 p-2 rounded-full w-7 h-7 flex mr-2"
        >
          <FaFacebookF className="text-blue-500" data-testid="facebook-icon" />
        </div>
      );
    }

    if (site === "yelp") {
      return (
        <div
          key={index}
          className="bg-red-100 p-2 rounded-full w-7 h-7 flex mr-2"
        >
          <FaYelp className="text-red-600" />
        </div>
      );
    }
    if (site === "twitter") {
      return (
        <div
          key={index}
          className="bg-sky-100 p-2 rounded-full w-7 h-7 flex mr-2"
        >
          <FaTwitter className="text-sky-500" />
        </div>
      );
    }

    if (site === "angieslist") {
      return (
        <div
          key={index}
          className="bg-orange-100 p-2 rounded-full w-7 h-7 flex mr-2"
        >
          <FaList className="text-orange-500" />
        </div>
      );
    }

    if (site === "healthgrades") {
      return (
        <div
          key={index}
          className="bg-blue-100 p-2 rounded-full w-7 h-7 flex mr-2"
        >
          <FaHeart className="text-blue-800" />
        </div>
      );
    }

    if (site.length === 0) {
      return "0 Clicks";
    }
  });

  return final;
};

const SocialIcons = ({ stats }) => <SocialLabel sites={stats} />;

export default SocialIcons;
