import { statuses } from "./constants";

export default function getBusinessesProducts(products) {
  const businessProducts = [...new Set(products)];

  const businessProductsFormatted = businessProducts.map((product) => {
    if (
      product.statusesId === statuses.inactive ||
      product.statusesId === statuses.cancelled ||
      product.statusesId === statuses["in-progress"]
    ) {
      return null;
    }

    switch (product.label) {
      case "rep":
        return {
          label: "Review Generation",
          path: "reputation/stats",
        };
      case "seo":
        return {
          label: "SEO Report",
          path: "search",
        };
      case "ppc":
        return {
          label: "Google Adwords",
          path: "advertising/stats",
        };
      case "website":
        return {
          label: "Website Analytics",
          path: "website/stats",
        };
      case "reviewGeneration":
        return null;
      default:
        return product;
    }
  });
  return businessProductsFormatted;
}
