import React from "react";
interface Props {
  title: string;
  body: string;
  children?: React.ReactNode;
}

const SubPageHeading = ({ title, body, children }: Props) => {
  return (
    <div className="sm:flex sm:items-center sm:justify-between">
      <div>
        <h2 className="text-2xl font-bold text-secondary sm:text-3xl sm:tracking-tight">
          {title}
        </h2>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">{body}</p>
      </div>
      {children && <div className="mt-3 sm:mt-0 sm:ml-4">{children}</div>}
    </div>
  );
};

export default SubPageHeading;
