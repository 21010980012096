interface StatTableProps {
  data: any;
  name: string;
}

const StatTable = ({ data, name }: StatTableProps) => {
  if (!data) return null;
  return (
    <div className=" bg-white justify-self-stretch">
      <div className="h-auto relative bg-white pt-5 pb-4  sm:pt-6 overflow-hidden">
        <p className="text-lg font-medium pb-4 text-gray-900 truncate text-center">
          {name}
        </p>
        <div className="grid grid-cols-3">
          <div className="text-gray-500 uppercase tracking-wider text-xs pl-6 pb-4 col-span-2">
            Page Name
          </div>
          <div className="text-gray-500 uppercase tracking-wider text-xs justify-self-end pr-6">
            Views
          </div>
          {data.map((item) => (
            <div
              key={item.dimensionValues[0].value}
              className="grid grid-cols-3 border-t py-4 text-sm border-gray-200 col-span-3"
            >
              <div className="truncate col-span-2 pl-6 text-gray-700">
                {item.dimensionValues[0].value}
              </div>
              <div className="justify-self-end pr-6 text-primary font-semibold">
                {item.metricValues[0].value}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default StatTable;
