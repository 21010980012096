import { useEffect, useState } from "react";
import * as auth from "../../authProvider";
import { useForm } from "react-hook-form";
import { useParams, Link } from "react-router-dom";

import Alert from "../../Components/Alert/Alert";

const NotFound = () => {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Reset link is expired or not valid. Please check your email again or
            request a new reset link
          </h3>
        </div>
      </div>
    </div>
  );
};

const ResetSuccess = () => {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">
            Your Password has been Reset
          </h3>
          <div className="mt-2 text-sm text-green-700">
            <p>You may now login with your new password.</p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              <Link to="/">
                <button
                  type="button"
                  className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                >
                  Return to Login
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ResetPasswordForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [accountId, setAccountId] = useState("");
  const [error, setError] = useState("");
  const [isValidToken, setIsValidToken] = useState(true);
  const [isResetSuccess, setIsResetSuccess] = useState(false);

  const { token } = useParams<{ token: string }>();

  const checkToken = async () => {
    try {
      const respose = await auth.verifyGuid(token || "");
      setAccountId(respose.accountsId);
    } catch (err: any) {
      setIsValidToken(false);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const handleResetPassword = async (data) => {
    const body = {
      password: data.password,
      guid: token,
      accountsId: accountId,
    };
    try {
      const response: any = await auth.setNewPassword(body);

      if (response.message === "Password reset successful") {
        setIsResetSuccess(true);
      }
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <>
      {!isValidToken ? (
        <NotFound />
      ) : (
        !isResetSuccess && (
          <form
            action="#"
            method="POST"
            className="space-y-6"
            onSubmit={handleSubmit(handleResetPassword)}
          >
            <div>{error && <Alert msg={error} />}</div>

            <div className="space-y-1">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  type="password"
                  {...register("password", {
                    required: true,
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="space-y-1">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  type="password"
                  {...register("confirmPassword", {
                    required: true,
                    validate: (val: string) => {
                      if (watch("password") !== val) {
                        return "Your passwords do no match";
                      }
                    },
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {errors.confirmPassword && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    Your passwords do not match
                  </p>
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Reset Password
              </button>
            </div>
          </form>
        )
      )}
      {isResetSuccess && <ResetSuccess />}
    </>
  );
};
