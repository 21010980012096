import { ResponsivePie } from "@nivo/pie";

const PieGraph = ({ data, name }) => {
  if (!data) return null;

  return (
    <div className=" bg-white justify-self-stretch">
      <div className="h-80 relative bg-white pt-5 pb-4 px-4 sm:pt-6 sm:px-6 overflow-hidden justify-self-stretch">
        <p className="text-lg font-medium pb-4 text-gray-900 truncate text-center">
          {name}
        </p>
        <ResponsivePie
          data={data}
          margin={{ top: 0, right: 80, bottom: 100, left: 80 }}
          innerRadius={0.5}
          padAngle={3}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          enableArcLinkLabels={false}
          colors={["#bd1961", "#46b2bd", "#ec9047", "#5a3686", "#5b7bd6"]}
          colorBy="index"
          arcLabelsTextColor={"#ffffff"}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 20,
              itemWidth: 80,
              itemHeight: 10,
              itemsSpacing: 0,
              symbolSize: 20,
              itemDirection: "top-to-bottom",
              symbolShape: "circle",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default PieGraph;
