import ReviewCard from "../ReviewCard";
import ReviewModal from "../ReviewCard/ReviewModal";
import { useState } from "react";

const ReviewList = ({ reviews }) => {
  const [reviewDetails, setReviewDetails] = useState(null);

  return (
    <>
      <ReviewModal reviewDetails={reviewDetails} />

      <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {reviews.map((review) => (
          <div className="border-b border-r">
            <ReviewCard
              key={review.review_id}
              review={review}
              handleModal={(review) => setReviewDetails(review)}
            />
          </div>
        ))}
      </ul>
    </>
  );
};

export default ReviewList;
