import React from "react";

import Logo from "../../logo.svg";
import { ResetPasswordForm } from "Modules/ResetPassword";
import LoginAnimation from "Components/LoginAnimation/LoginAnimation";

const ResetPassword = () => {
  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img src={Logo} alt="Goldn Logo" width="70px" />

            <h2 className="mt-6 text-3xl font-extrabold text-primary">
              Reset Your Password
            </h2>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <ResetPasswordForm />
            </div>
          </div>
        </div>
      </div>

      <div className="hidden lg:block relative w-0 flex-1 bg-gray-50">
        <LoginAnimation />
      </div>
    </div>
  );
};

export default ResetPassword;
