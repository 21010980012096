import React from "react";
import { CgWebsite } from "react-icons/cg";
import {
  AiOutlineStar,
  AiOutlineBarChart,
  AiOutlineGoogle,
} from "react-icons/ai";
import { MdBarChart, MdStar } from "react-icons/md";
import { Link } from "react-router-dom";

const ProductLinks = ({ location, products }) => {
  function renderIcons(name) {
    switch (name) {
      case "Website Analytics":
        return (
          <div className="bg-accent rounded-md p-2">
            <CgWebsite className="w-6 h-6 text-white" />
          </div>
        );
      case "Google Adwords":
        return (
          <div className="bg-link rounded-md p-2">
            <AiOutlineGoogle className="w-6 h-6 text-white" />
          </div>
        );
      case "SEO Report":
        return (
          <div className="bg-purple-800 rounded-md p-2">
            <MdBarChart className="w-6 h-6 text-white" />
          </div>
        );
      case "Review Generation":
        return (
          <div className="bg-secondary rounded-md p-2">
            <MdStar className="w-6 h-6 text-white" />
          </div>
        );

      default:
        return null;
    }
  }
  return (
    <div className="">
      {/* <p className="text-sm uppercase font-semibold mb-4 text-secondary">
          Services
        </p> */}
      {products.map((product) => {
        if (!product) return null;
        return (
          <Link
            to={`/locations/${location.id}/${product?.path}`}
            key={product.label}
            className="flex items-center p-2 text-base font-bold text-primary rounded-lg hover:text-gray-600 group  mr-10 mb-5"
          >
            <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-fountain-100 mr-4">
              <span className="text-xs font-medium leading-none text-fountain-700">
                {renderIcons(product.label)}
              </span>
            </span>
            <p className="flex-1 whitespace-nowrap text-sm">{product?.label}</p>
          </Link>
        );
      })}
    </div>
  );
};

export default ProductLinks;
