import { useState, useEffect } from "react";

import SubPageHeading from "Components/SubPageHeading";
import React from "react";
import AdvertisingNav from "../AdvertisingNav.tsx";
import StatTable from "Modules/Stats/StatTable/StatTable";
import KeywordList from "Modules/Advertising/KeywordList";
import { useGoogleAnalytics } from "api/useGoogle";
import Fullpagespinner from "Components/Loaders/FullPageSpinner";
import Pagination from "Components/Pagination/Pagination";

const AdvertisingSearches = () => {
  const [startDate, setStartDate] = useState("7daysAgo");
  const [endDate, setEndDate] = useState("yesterday");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [viewId, setViewId] = useState("182495075");
  const metrics = ["ga:users", "ga:adClicks"];
  const [keywords, setKeywords] = useState([]);
  const { data, isLoading, isError, error } = useGoogleAnalytics(
    viewId,
    "120daysAgo",
    "today",
    metrics
  );

  useEffect(() => {
    setKeywords(data?.reports[4].data.rows);
  }, [data, currentPage]);

  const paginate = (items, page = 1, perPage = 10) =>
    items.slice(perPage * (page - 1), perPage * page);

  console.log(
    "🚀 ~ file: index.tsx ~ line 921 ~ AdvertisingSearches ~ data",
    data
  );
  if (isLoading) {
    return <Fullpagespinner />;
  }
  return (
    <>
      <SubPageHeading
        title="Keyword Searches"
        body="Analytics for your Pay-Per-Click Campaign"
      />
      <AdvertisingNav />
      {/* <KeywordList data={keywords} /> */}
      {/* <Pagination
        currentPage={currentPage}
        totalCount={30}
        pageSize={pageSize}
        onPageChange={(page) => setCurrentPage(page)}
      /> */}
    </>
  );
};

export default AdvertisingSearches;
