import Container from "./Container";
import {
  useParams,
  Link,
  Routes,
  Route,
  Outlet,
  useOutletContext,
} from "react-router-dom";
import { useBusinessData } from "api/useBusinessData";
import { useEffect, useState } from "react";
import getBusinessesProducts from "../utils/getBusinessesProducts";
import ProductNav from "../Components/ProductNav";
import Search from "./Search/Search";
import Reputation from "./Reputation/Reputation";
import Website from "./Website/Website";
import Container3 from "./Container3";
import React from "react";
import { LocationContext } from "context/locationContext";
import Loader from "Components/Loaders/Loader";
import ProductHeader from "Components/ProductHeader";
import { useLocation } from "react-router-dom";
import LocationDetails from "Modules/LocationDetails";

const LocationContainer = () => {
  let { id }: any = useParams();
  const { data: location, isLoading } = useBusinessData(id);
  const [products, setProducts] = useState<string[]>([]);
  const path = useLocation();
  console.log(path.pathname.split("/"));
  useEffect(() => {
    if (location) {
      setProducts(getBusinessesProducts(location.products));
    }
  }, [location, isLoading]);

  if (!location) <Loader />;

  return (
    <Container3
      title={(location && location?.commonName) || location?.name}
      padding={false}
    >
      {!path.pathname.split("/")[3] ? (
        <LocationDetails />
      ) : (
        <LocationContext.Provider value={location}>
          <Outlet />
        </LocationContext.Provider>
      )}
    </Container3>
  );
};

export default LocationContainer;
