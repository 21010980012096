import React, { Component } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const GoogleMapContainer = ({
  width = "400px",
  height = "400px",
  lat = -3.745,
  lng = -38.523,
}) => {
  const containerStyle = {
    width,
    height,
  };

  const center = {
    lat,
    lng,
  };

  const position = {
    lat,
    lng,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyA3F1q7VknIRWK5_POXFb8hIntHRbw5bcY">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        {/* Child components, such as markers, info windows, etc. */}
        <Marker position={position} />
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapContainer;
