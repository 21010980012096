import { XCircleIcon } from "@heroicons/react/solid";

const Alert = ({
  msg,
  background = "error",
  icon = "error",
  text = "error",
}) => {
  const backgroundColors = {
    error: "bg-error-100 border-l-4 border-error-600 p-4",
    success: "bg-success-100 border-l-4 border-success-600 p-4",
  };

  const iconColors = {
    error: "text-error-600 h-5 w-5",
    success: "text-success-600 h-5 w-5",
  };

  const textColors = {
    error: "text-error-600 text-sm",
    sucess: "text-success-600 text-sm",
  };
  return (
    <div className={`${backgroundColors[background]} ...`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon
            className={`${iconColors[icon]} ...`}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className={`${textColors[icon]} ...`}>{msg}</p>
        </div>
      </div>
    </div>
  );
};

export default Alert;
