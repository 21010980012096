import { useMutation, useQuery } from "react-query";
import { useClient } from "../../context/authContext";

export default function useImpersonate() {
  const client = useClient();

  const impersonate = (accountsId) => {
    const data = {
      accountsId,
    };
    const res = client("impersonate", { data });
    return res;
  };
  const account = useMutation(impersonate, {
    onSuccess: () => {
      console.log("success impersonate");
    },
  });

  return account;
}
