import { useQuery, useMutation, useQueryClient } from "react-query";
import { useClient } from "../context/authContext";

function useGetReviews(businessId, limit = 8, offset = 0) {
  if (offset === 1) {
    offset = 0;
  } else {
    offset = offset * limit - 10;
  }

  const client = useClient();

  const data = {
    businessId,
    limit,
    offset,
  };

  return useQuery(["getReviews", offset], () => client("getReviews", { data }));
}

function useGetReviewReport(businessId) {
  const client = useClient();
  const data = { businessId };
  return useQuery("getReviewReport", () => client("getReviewReport", { data }));
}

export { useGetReviews, useGetReviewReport };
