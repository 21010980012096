import React from "react";
import classNames from "classnames";

import {
  DeviceMobileIcon,
  MailIcon,
  DesktopComputerIcon,
} from "@heroicons/react/outline";
const FormToggle = ({ device, setDevice }) => {
  return (
    <div className="grid grid-cols-3 border-b">
      <div
        className="flex cursor-pointer hover:bg-gray-50 p-4 border-r items-center"
        onClick={() => setDevice("phone")}
      >
        <DeviceMobileIcon className="w-6 h-6" />
        <div className="pl-4">
          <h2
            className={classNames("font-bold text-sm", {
              "text-secondary": device === "phone",
            })}
          >
            Text Message
          </h2>
          <p className="text-xs">Update the text message copy</p>
        </div>
      </div>
      <div
        className="flex cursor-pointer hover:bg-gray-50 p-4 border-r items-center"
        onClick={() => setDevice("email")}
      >
        <MailIcon className="w-6 h-6" />
        <div className="pl-4">
          <h2
            className={classNames("font-bold text-sm", {
              "text-secondary": device === "email",
            })}
          >
            Email Message
          </h2>
          <p className="text-xs">Update the email message copy</p>
        </div>
      </div>

      <div
        className="flex cursor-pointer hover:bg-gray-50 p-4 border-r items-center"
        onClick={() => setDevice("desktop")}
      >
        <DesktopComputerIcon className="w-6 h-6" />
        <div className="pl-4">
          <h2
            className={classNames("font-bold text-sm", {
              "text-secondary": device === "desktop",
            })}
          >
            Landing Page
          </h2>
          <p className="text-xs">Update the landing page text</p>
        </div>
      </div>
    </div>
  );
};

export default FormToggle;
