import ProductNav from "Components/ProductNav";

const ProductHeader = ({ location }) => {
  return (
    <div className="py-8 bg-white">
      <h2 className="text-4xl font-bold ml-8 mb-8 text-primary">
        {location?.commonName || location?.name}
      </h2>
      <ProductNav products={location?.products} locationId={location?.id} />
    </div>
  );
};

export default ProductHeader;
