import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid";
import classNames from "classnames";
const KeywordReportTable = ({ report }) => {
  return (
    <div className="mt-8 flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Keyword
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Rank
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    URL
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Change
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {report.map((keywords, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-primary">
                      {keywords.keyword}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {keywords.map((keyword) => {
                        return (
                          <div
                            key={keyword.id}
                            className="flex justify-start my-4"
                          >
                            <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-secondary mr-4">
                              <span className="text-xs font-medium leading-none text-white">
                                {keyword.rank}
                              </span>
                            </span>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-reputation-300 text-white">
                              {keyword.type}
                            </span>
                          </div>
                        );
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {keywords.map((keyword) => {
                        return (
                          <div
                            key={keyword.id}
                            className="flex justify-start my-4"
                          >
                            <a
                              href={keyword.search_url}
                              className="text-link"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {keyword.url}
                            </a>
                          </div>
                        );
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {keywords.map((keyword) => {
                        const change = keyword.rank - keyword.last;

                        return (
                          <div className="flex" key={keyword.id}>
                            <span className="inline-flex items-center my-3">
                              {Math.sign(change) === -1 ? (
                                <ArrowSmUpIcon
                                  className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                                  aria-hidden="true"
                                />
                              ) : Math.sign(change) === 1 ? (
                                <ArrowSmDownIcon
                                  className="self-center flex-shrink-0 h-5 w-5 text-red-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                "No Change"
                              )}

                              {change !== 0 && Math.abs(change)}
                            </span>
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeywordReportTable;
