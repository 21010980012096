import moment from "moment";
import { formatDate, formatDateWithDashes } from "utils/dateHelpers";
// Recieves Google Analtyics API data and formats for Nivo Pie Chart

// [
//   {
//     "id": "python",
//     "label": "python",
//     "value": 459,
//     "color": "hsl(65, 70%, 50%)"
//   },
//   {
//     "id": "php",
//     "label": "php",
//     "value": 97,
//     "color": "hsl(175, 70%, 50%)"
//   }
// ]
const formatPieData = (pieData) => {
  if (!pieData) return;

  const formatedData = pieData.map((label) => {
    const obj = {};
    obj.id = label?.dimensionValues[0].value;
    obj.label = label?.dimensionValues[0].value;
    obj.value = label?.metricValues[0].value;
    return obj;
  });
  return formatedData;
};

// Recieves Google Analytics API data and formats for Nivo Bar Graph
const formatBarData = (barData) => {
  if (!barData) return;

  return barData.map((x) => {
    const obj = {};
    obj.label = moment(x.dimensions[0], "YYYYMMDD").format("MMM Do");
    obj.value = x.metrics[0].values[0];
    return obj;
  });
};

const formatReviewStats = (reviewStats) => {
  if (!reviewStats) return [];

  const lineData = [];

  const textSent = {};
  const emailSent = {};
  const reviewsRecieved = {};

  textSent.id = "Text Sent";
  textSent.data = [];
  emailSent.id = "Email Sent";
  emailSent.data = [];
  reviewsRecieved.id = "Reviews Received";
  reviewsRecieved.data = [];

  // used to formate date for line chart component
  const formatFullDate = (month, year) => {
    const date = new Date(`${year}-${month}-01 12:00:00`);
    const formatedDate = `${date.getFullYear()}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;

    return formatedDate;
  };

  const t = reviewStats.messagesSentOverTime.map((item) => {
    let obj = {};
    obj.y = item.textMessageCount;
    obj.x = formatFullDate(item.month, item.year);
    return obj;
  });
  textSent.data.push(...t);

  const e = reviewStats.messagesSentOverTime.map((item) => {
    let obj = {};
    obj.y = item.emailCount;
    obj.x = formatFullDate(item.month, item.year);
    return obj;
  });

  emailSent.data.push(...e);

  const r = reviewStats.reviews.map((item) => {
    let obj = {};
    obj.y = item.reviews;
    obj.x = formatFullDate(item.month, item.year);
    return obj;
  });

  reviewsRecieved.data.push(...r);

  lineData.push(textSent, emailSent);

  return lineData;
};

// formats BrightLocal API data into table data for UI
const formatSearchRankReport = (searchReport) => {
  const formatedReport = [];
  searchReport = Object.values(searchReport);
  const keywords = searchReport[2].keywords;

  keywords.forEach((keyword) => {
    const reportItem = searchReport[2].rankings[keyword].google;

    formatedReport.push(
      reportItem.filter((x) => x.type === "Places" || x.type === "Organic")
    );
  });

  formatedReport.map((x) => {
    return (x.keyword = keywords[formatedReport.indexOf(x)]);
  });
  // console.log("formated", formatedReport);
  return formatedReport;
};
// formats review Report site sources for use with bar graph
const formatReviewSources = (reviewReport) => {
  if (!reviewReport) return;

  const formatedData = reviewReport.map((item) => {
    const obj = {};
    obj.id = item.site;
    obj.label = item.site;
    obj.value = item.count;
    return obj;
  });
  return formatedData;
};

const formatStatTable = (data) => {
  if (!data) return;
  const formatedData = data
    .sort((a, b) => b.dimensionValues[0].value - a.metricValues[0].value)
    .slice(0, 10);
  return formatedData;
};

const formatClicksOverTime = (data) => {
  console.log("data!!!!", data);
  if (!data) return;
  const formatedData = [];
  data.forEach((item) => {
    // dates
    let x = formatDateWithDashes(item.dimensionValues[1].value);
    // number of clicks
    let y = item.metricValues[0].value;
    formatedData.push({
      x,
      y,
    });
  });
  return formatedData;
};

const formatWebVists = (data) => {
  if (!data) return;
  const result = [];
  const webVisits = {};

  webVisits.id = "Web Visits";
  webVisits.data = [];

  const w = data.map((item) => {
    let obj = {};
    obj.y = Number(item.metricValues[0].value);
    obj.x =
      item.dimensionValues[0].value.substring(0, 4) +
      "-" +
      item.dimensionValues[0].value.substring(4, 6) +
      "-" +
      item.dimensionValues[0].value.substring(6, 8);
    return obj;
  });

  webVisits.data.push(...w);
  //console.log(webVisits);
  result.push(webVisits);
  return result;
};

export {
  formatPieData,
  formatBarData,
  formatReviewStats,
  formatSearchRankReport,
  formatReviewSources,
  formatStatTable,
  formatClicksOverTime,
  formatWebVists,
};
