import React from "react";
import ProductLinks from "./ProductLinks";
import getBusinessesProducts from "../../utils/getBusinessesProducts";
import { Link } from "react-router-dom";
const LocationListItem = ({ location }) => {
  const products = getBusinessesProducts(location.products);

  return (
    <div className="flex flex-col bg-white border-b md:flex-row p-4">
      <div className="flex flex-col p-4 leading-normal w-full">
        <h5 className=" text-lg font-bold tracking-tight text-secondary">
          <Link to={`/locations/${location.id}/`}>
            {" "}
            {location.commonName || location.name}
          </Link>
        </h5>
        <p className="mb-3 text-sm text-gray-700 dark:text-gray-400">
          {location.address} {location.city}, {location.state}
        </p>
        <ProductLinks location={location} products={products} />
      </div>
    </div>
  );
};

export default LocationListItem;
