import { useState, useContext, useEffect } from "react";
import SubPageHeading from "Components/SubPageHeading";
import React from "react";
import AdvertisingNav from "../AdvertisingNav.tsx";
import StatBox from "Modules/Stats/StatBox/StatBox";
import { useGoogleAnalyticsGA4 } from "../../../api/useGoogleGA4";
import LineGraph from "Modules/Stats/LineGraph/LineGraph";
import { formatClicksOverTime } from "utils/dataHelpers";
import { formatDate } from "utils/dateHelpers";
import Loader from "Components/Loaders/Loader";
import Fullpagespinner from "Components/Loaders/FullPageSpinner";
import { LocationContext } from "context/locationContext";
import Select from "Components/Select";
import ErrorBoundary from "utils/errorBoundry";
import DateRange from "Components/DateRange";
import ReportHeading from "Components/ReportHeading";
import { ChartBarIcon } from "@heroicons/react/outline";
const AdvertisingStats = () => {
  const location = useContext(LocationContext);

  const [startDate, setStartDate] = useState("30daysAgo");
  const [endDate, setEndDate] = useState("today");

  const viewId = location?.websites[0].googleAnalyticsProfileId;
  console.log(
    "🚀 ~ file: index.js ~ line 20 ~ AdvertisingStats ~ viewId",
    viewId
  );
  const updateDateRange = (start) => {
    setStartDate(start);
    // setEndDate(end);
  };

  const ga_options = [
    // {
    //   label: "Ads Clicks Over Time",
    //   dimensions: [
    //     {
    //       name: "ga:adwordsCapmpaignID",
    //       name: "ga:date",
    //     },
    //   ],
    //   metrics: [
    //     {
    //       expression: "ga:adClicks",
    //     },
    //   ],
    // },
    // {
    //   label: "Ads Clicks",
    //   dimensions: [],
    //   metrics: [
    //     {
    //       expression: "ga:adClicks",
    //     },
    //   ],
    // },
    // {
    //   label: "Ads Click Through Rate",
    //   dimensions: [],
    //   metrics: [{ expression: "ga:CTR" }],
    // },
    // {
    //   label: "Ads Cost Per Click",
    //   dimensions: [],
    //   metrics: [{ expression: "ga:CPC" }],
    // },
    {
      label: "Ads Impressions",
      dimensions: [{ name: "sessionCampaignName" }],
      metrics: [{ name: "advertiserAdImpressions" }],
    },
    {
      label: "Ads Clicks",
      dimensions: [{ name: "sessionCampaignName" }],
      metrics: [{ name: "advertiserAdClicks" }],
    },
    {
      label: "Ads cost",
      dimensions: [{ name: "sessionCampaignName" }],
      metrics: [{ name: "advertiserAdCost" }],
    },
    {
      label: "Ads Clicks Over Time",
      dimensions: [{ name: "sessionCampaignName" }, { name: "date" }],
      metrics: [{ name: "advertiserAdClicks" }],
      orderBys: [
        {
          desc: false,
          dimension: { dimensionName: "date", orderType: "NUMERIC" },
        },
      ],
    },
  ];

  const { data, isLoading, isError, error } = useGoogleAnalyticsGA4(
    viewId,
    startDate,
    endDate,
    ga_options,
    "AdsStats"
  );

  const clicks = data?.reports[1].rows?.[0].metricValues[0].value || "";
  const impressions = data?.reports[0].rows?.[0].metricValues[0].value || "";
  const cost = data?.reports[2].rows?.[0].metricValues[0].value || "";
  const costPerClick = parseFloat(cost / clicks).toFixed(2);
  const clickThroughRate = parseFloat((clicks / impressions) * 100).toFixed(2);

  const lineGraphData = [];

  if (data) {
    lineGraphData[0] = {
      id: "Dates",
      data: formatClicksOverTime(data?.reports[3].rows),
    };
  }

  const handleDateChange = (e) => {
    console.log(e.target.value);
    updateDateRange(e.target.value);
  };

  return (
    <>
      <SubPageHeading
        title="Google Adwords Stats"
        body="Analytics for your Pay-Per-Click Campaign"
      />
      <AdvertisingNav />
      <ReportHeading
        title="Google Adwords Stats"
        childComp={<DateRange onChange={handleDateChange} />}
      />
      <ErrorBoundary>
        {!isLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-3">
            <ErrorBoundary>
              <StatBox
                name="Click Through Rate"
                stat={`${clickThroughRate}%`}
                bgColor="advertising-100"
                statColor="advertising-300"
                iconColor="advertising-300"
                labelColor="shadow"
              />
            </ErrorBoundary>
            <StatBox
              name="Clicks / Impressions"
              stat={clicks + " / " + impressions}
              icon={ChartBarIcon}
              bgColor="website-100"
              iconColor="website-300"
              statColor="website-300"
              labelColor="shadow"
            />
            <StatBox
              name="Cost Per Click"
              stat={`$${costPerClick}`}
              icon={ChartBarIcon}
              bgColor="search-100"
              statColor="search-300"
              iconColor="search-300"
              labelColor="shadow"
            />
            <div className="col-span-3 border-b">
              <ErrorBoundary>
                <LineGraph name="Clicks Over Time" data={lineGraphData} />
              </ErrorBoundary>
            </div>
          </div>
        ) : (
          <Fullpagespinner />
        )}
      </ErrorBoundary>
    </>
  );
};

export default AdvertisingStats;
