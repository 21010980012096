import { Outlet } from "react-router-dom";
import { useContext } from "react";
import { LocationContext } from "context/locationContext";
import ProductNav from "Components/ProductNav";
import ProductHeader from "Components/ProductHeader";

const Reputation = () => {
  const location: any = useContext(LocationContext);

  return (
    <>
      <ProductHeader location={location} />
      <main className="flex-1 overflow-y-auto">
        <section
          aria-labelledby="primary-heading"
          className="min-w-0 flex-1 h-full flex flex-col lg:order-last"
        >
          <Outlet />
        </section>
      </main>
    </>
  );
};

export default Reputation;
