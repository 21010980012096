import { useAuth } from "./context/authContext";
import AuthenticatedApp from "./AuthenticatedApp";
import UnauthenticatedApp from "./UnauthenticatedApp";
import AdminApp from "AdminApp";

const App = () => {
  const { user } = useAuth();

  return (
    <>
      {user?.userRole === "admin" ? (
        <AdminApp />
      ) : user && user?.userRole !== "admin" ? (
        <AuthenticatedApp />
      ) : (
        <UnauthenticatedApp />
      )}
    </>
  );
};

export default App;
