import SubPageHeading from "Components/SubPageHeading";
import { useForm } from "react-hook-form";
import Button from "Components/Button";
// import { useLocation } from "Pages/LocationDetails";
import React, { useEffect } from "react";
import { useBusinessData, useUpdateBusiness } from "api/useBusinessData";
import { LocationContext } from "context/locationContext";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import ReputationNav from "Modules/Reputation/ReputationNav";
import DevicePreview from "Modules/Reputation/ReputationSettings/devicePreview";
import FormToggle from "Modules/Reputation/ReputationSettings/FormToggle";
import TextMessageForm from "Modules/Reputation/ReputationSettings/TextMessageForm";
import EmailMessageForm from "Modules/Reputation/ReputationSettings/EmailMessageForm";
import LandingPageForm from "Modules/Reputation/ReputationSettings/LandingPageForm";

const ReputationSettings = () => {
  // let { id } = useParams();
  // const { data: location, isLoading } = useBusinessData(id);
  const location = useContext(LocationContext);
  const [formData, setFormData] = useState(null);
  const [device, setDevice] = useState("phone");

  useEffect(() => {
    setFormData(location?.product_reviewGeneration);
  }, [location]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log("data", data);
    setFormData({ ...formData, data });
    const reputationSettings = {
      businessId: location.id,
      product_reviewGeneration: formData,
    };
    console.log("rep", reputationSettings);

    updateBusiness(reputationSettings);
  };

  const { mutate: updateBusiness } = useUpdateBusiness();

  return (
    <div>
      <div className="px-8 pb-8">
        <SubPageHeading
          title="Reputation Settings"
          body="Settings for reputation"
          color="reputation-300"
        />
      </div>
      <ReputationNav />
      <div className="">
        <FormToggle device={device} setDevice={setDevice} />
        <div className="grid grid-cols-6">
          {device === "phone" && (
            <TextMessageForm
              formData={formData}
              onSubmit={onSubmit}
              setFormData={setFormData}
            />
          )}

          {device === "email" && (
            <EmailMessageForm
              formData={formData}
              onSubmit={onSubmit}
              setFormData={setFormData}
            />
          )}

          {device === "desktop" && (
            <LandingPageForm
              formData={formData}
              onSubmit={onSubmit}
              setFormData={setFormData}
              locationId={location.id}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReputationSettings;
