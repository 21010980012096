/* eslint-disable jsx-a11y/anchor-is-valid */
import PhoneNumber from "Components/PhoneNumber/PhoneNumber";

import SocialIcons from "Components/SocialIcons/SocialIcons";
import { ChatAltIcon } from "@heroicons/react/outline";
import { formatDateAndTime } from "utils/dateHelpers";
import ConfirmationAlert from "Components/Alert/Confirmation";
import { useEffect, useState } from "react";

const OpenLabel = ({ stats }) => {
  const opens = stats.reduce((prev, next) => {
    return prev + (next.type === "open");
  }, 0);

  if (opens === 0) {
    return (
      <div>
        <span className="px-2 inline-flex text-xs leading-5 font-semibold text-gray-300 rounded-full">
          {opens} Opens
        </span>
      </div>
    );
  }

  return (
    <div>
      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        {opens} Opens
      </span>
    </div>
  );
};

const NegFeedback = ({ stats }) => {
  const negative = stats.filter((x) => x.type === "negative");

  if (negative.length > 0) {
    return (
      <ChatAltIcon
        width={24}
        className="text-red-500"
        data-testid="reputation-negative"
      />
    );
  } else {
    return (
      <ChatAltIcon
        width={24}
        className="text-gray-300"
        data-testid="reputation-no-feedback"
      />
    );
  }
};

const alert = {
  title: "Delete Me",
  message: "Are you sure you want to delete this message from your report?",
  confirmButton: "Delete",
  cancelButton: "Cancel",
};

const MessageList = ({ data, type, handleDelete, status }) => {
  const [open, setOpen] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);

  useEffect(() => {
    if (status === "success") {
      setOpen(false);
      console.log("o", open);
    }
  }, [status]);

  return (
    <>
      <ConfirmationAlert
        handleConfirmation={() => handleDelete(messageToDelete)}
        handleClose={setOpen}
        toggle={open}
        alert={alert}
      />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Sent By
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {type === "sms" ? "Phone Number" : "Email"}
                    </th>

                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Opens
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Sites Clicked
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Negative Feedback
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data.map((message) => (
                    <tr key={message.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div>
                            <div className="text-sm font-medium text-gray-900">
                              {message.firstName} {message.lastName}
                            </div>
                            <div className="text-sm text-gray-500">
                              {formatDateAndTime(message.createdAt)}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          <span className="inline-flex text-xs leading-5 font-semibold rounded-full ">
                            {type === "sms" ? (
                              <PhoneNumber phoneNumber={message.phone} />
                            ) : (
                              message.email
                            )}
                          </span>
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <OpenLabel stats={message.stats} />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <div className="flex items-center">
                          <SocialIcons stats={message.stats} />
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <NegFeedback stats={message.stats} />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <a
                          onClick={() => {
                            setMessageToDelete(message.id);
                            setOpen(!open);
                          }}
                          className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                          data-testid="delete-button"
                        >
                          Delete
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageList;
