import Container from "./Container";
import LocationList from "../Modules/LocationList/LocationList";
import LocationListAlt from "Modules/LocationList/LocationListAlt";
import useAppData from "../api/useAppdata";
import { useAuth } from "../context/authContext";
import Container2 from "./Container2";
import Container3 from "Pages/Container3";
import ErrorBoundary from "utils/errorBoundry";

const Business = () => {
  const { user } = useAuth();
  const { data: locations, isLoading } = useAppData(user.id);

  return (
    // <Container2 title={`Welcome ${user.account.firstName || null}!`}>
    //   {!isLoading && <LocationList locations={locations.businessData} />}
    // </Container2>
    <Container3 title={`Welcome ${user.account.firstName || null}!`}>
      <h2 className="text-4xl font-bold m-8 font-Poppins text-primary">
        Your Locations
      </h2>
      <ErrorBoundary>
        {!isLoading && <LocationListAlt locations={locations?.businessData} />}
      </ErrorBoundary>
    </Container3>
  );
};

export default Business;
