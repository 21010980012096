const StatusMessage = () => {
  return (
    <div className=" bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          {/* <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" /> */}
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            You are using the BETA version of the new Goldn Platform.{" "}
            <a
              href="/support"
              className="font-medium text-yellow-700 underline hover:text-yellow-600"
            >
              Contact Our Support Team with any questions.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatusMessage;
