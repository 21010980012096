import { useContext } from "react";
import { LocationContext } from "context/locationContext";
import ProductNav from "Components/ProductNav";
import { Outlet } from "react-router-dom";
import ProductHeader from "Components/ProductHeader";
import SubPageHeading from "Components/SubPageHeading";
const Website = () => {
  const location: any = useContext(LocationContext);
  return (
    <>
      <ProductHeader location={location} />
      <main className="flex-1 overflow-y-auto">
        <SubPageHeading
          title="Website"
          body="Stay up-to-date with these helpful Search Ranking reports. Reports are run monthly and you can compare the current report to any previous report."
        />
        <section
          aria-labelledby="primary-heading"
          className="min-w-0 flex-1 h-full flex flex-col lg:order-last"
        >
          <Outlet />
        </section>
      </main>
    </>
  );
};

export default Website;
