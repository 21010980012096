import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useBusinessData, useUpdateBusiness } from "api/useBusinessData";
import DevicePreview from "./devicePreview";
import Button from "Components/Button";

const LandingPageForm = ({ formData, onSubmit, setFormData, locationId }) => {
  const landingURL = process.env.REACT_APP_LANDING_URL;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  return (
    <>
      <form className="col-span-2 p-8" onSubmit={handleSubmit(onSubmit)}>
        <label
          htmlFor="project-name"
          className="block text-sm font-medium text-gray-700 mt-4"
        >
          Intro Text
        </label>
        <div className="mt-2">
          <textarea
            defaultValue={formData?.landingText}
            {...register("landingText", {
              onChange: (e) => {
                setFormData({
                  ...formData,
                  landingText: e.target.value,
                });
                console.log(e.target.value);
              },
            })}
            className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md border p-2 h-28"
          />
        </div>
        <label
          htmlFor="project-name"
          className="block text-sm font-medium text-gray-700 mt-4"
        >
          CTA Text
        </label>
        <div className="mt-2">
          <textarea
            defaultValue={formData?.landingLinksText}
            {...register("landingLinksText", {
              onChange: (e) => {
                setFormData({
                  ...formData,
                  landingLinksText: e.target.value,
                });
              },
            })}
            className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md border p-2 h-28"
          />
        </div>

        <label
          htmlFor="project-name"
          className="block text-sm font-medium text-gray-700 mt-4"
        >
          Button Text
        </label>
        <div className="mt-2">
          <input
            defaultValue={formData?.landingPositiveButtonText}
            {...register("landingPositiveButtonText", {
              onChange: (e) => {
                setFormData({
                  ...formData,
                  landingPositiveButtonText: e.target.value,
                });
              },
            })}
            className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md border p-2"
          />
        </div>

        <label
          htmlFor="project-name"
          className="block text-sm font-medium text-gray-700 mt-4"
        >
          Contact Us Text
        </label>
        <div className="mt-2">
          <textarea
            defaultValue={formData?.landingNegativeText}
            {...register("landingNegativeText", {
              onChange: (e) => {
                setFormData({
                  ...formData,
                  landingNegativeText: e.target.value,
                });
                console.log(e.target.value);
              },
            })}
            className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md border p-2 h-28"
          />
        </div>

        <div className="my-4">
          <Button label="Submit" color="secondary" loading={false} />
        </div>
      </form>
      <div className="col-span-4 p-8">
        <DevicePreview device="desktop" text={formData} />
        {console.log(landingURL + locationId)}
        <a
          href={landingURL + locationId}
          rel="noreferrer"
          target="_blank"
          className="flex justify-center hover:text-secondary"
        >
          View Landing Page
        </a>
      </div>
    </>
  );
};

export default LandingPageForm;
