import { useGetReviews } from "api/useReviews";
import Fullpagespinner from "Components/Loaders/FullPageSpinner";
import ReviewList from "./ReviewList";
import { useState } from "react";
import Pagination from "Components/Pagination/Pagination";
import { useParams } from "react-router-dom";
import SubPageHeading from "Components/SubPageHeading";
import ReputationNav from "Modules/Reputation/ReputationNav";

const ReputationReviews = () => {
  let { id }: any = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;
  const { data, isLoading } = useGetReviews(id, pageSize, currentPage);

  console.log(data?.reviews);

  if (isLoading) {
    return <Fullpagespinner />;
  }
  return (
    <>
      <div className="px-8 pb-8">
        <SubPageHeading title="Reviews" body="Reviews you received online" />
      </div>
      <ReputationNav />
      <ReviewList reviews={data?.reviews} />
      <Pagination
        currentPage={currentPage}
        totalCount={data?.count}
        pageSize={pageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </>
  );
};

export default ReputationReviews;
