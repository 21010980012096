interface ReportHeadingProps {
  title: string;
  description?: string;
  childComp?: React.ReactNode;
}

const ReportHeading: React.FC<ReportHeadingProps> = ({
  title,
  description,
  childComp,
}) => {
  return (
    <div className="grid grid-cols-[1fr_500px] px-8 py-8 border-b">
      <div>
        <h2 className="text-sm font-bold uppercase align-middle text-website-300">
          {title}
        </h2>
        <p className="text-sm text-shadow">{description}</p>
      </div>

      <div className="">{childComp}</div>
    </div>
  );
};

export default ReportHeading;
