import { SearchIcon } from "@heroicons/react/solid";
import { useForm } from "react-hook-form";
import ResultsList from "./ResultsList";
import { useState } from "react";

const LocationSearch = () => {
  const [query, setQuery] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  return (
    <div className="flex-1 flex justify-between px-4 sm:px-6 lg:px-8">
      <div className="flex-1 flex">
        <form className="w-full flex md:ml-0">
          <label htmlFor="search-field" className="sr-only">
            Search Locations
          </label>
          <div className="relative w-full text-gray-400 focus-within:text-gray-600">
            <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
              <SearchIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            <input
              id="search-field"
              className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:placeholder-gray-400 sm:text-sm"
              placeholder="Search Locations"
              type="search"
              {...register("search", {
                onChange: (e) => setQuery(e.target.value),
              })}
            />
          </div>
        </form>
      </div>
      <ResultsList query={query} handleQuery={setQuery} handleReset={reset} />
    </div>
  );
};

export default LocationSearch;
