import React, { useState } from "react";
import { useGetTickets } from "api/useZendesk";
import TicketList from "./components/TicketList";
import Pagination from "Components/Pagination/Pagination";
import { useAuth } from "context/authContext";
import SubPageHeading from "Components/SubPageHeading";

const Tickets = () => {
  const { user } = useAuth();
  console.log(user);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { data } = useGetTickets(user?.email, pageSize, currentPage);
  return (
    <>
      <div className="p-8">
        <SubPageHeading
          title="Tickets"
          body="A list of your submitted tickets"
        />
      </div>
      {data && (
        <>
          <TicketList tickets={data.results} />{" "}
          <Pagination
            currentPage={currentPage}
            totalCount={data.count}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
      )}
    </>
  );
};

export default Tickets;
