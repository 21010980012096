import React from "react";

const Fullpagespinner = () => {
  return (
    <>
      <div id="circle6"></div>
    </>
  );
};

export default Fullpagespinner;
