import { useAuth } from "../../context/authContext";
import { alertError, alertSuccess } from "../../utils/alerts";
import { useClient } from "../../context/authContext";
import { useAccount } from "../../api/useAccount";
import Fullpagespinner from "../../Components/Loaders/FullPageSpinner";
import { useState } from "react";
import Container3 from "Pages/Container3";
import { Link } from "react-router-dom";
import Modal from "Components/Modal";
import { Outlet } from "react-router-dom";
import SubNavigation from "Components/SubNavigation/SubNavigation";
const Support = () => {
  const { user } = useAuth();
  const { data, isLoading } = useAccount(user.id);

  const NavItems = [
    {
      label: "Get In Touch",
      to: "contact",
    },
    {
      label: "Submit a New Ticket",
      to: "create-ticket",
    },
    {
      label: "View Submitted Tickets",
      to: "tickets",
    },
  ];

  if (isLoading || !data) {
    return <Fullpagespinner />;
  }
  return (
    <Container3 title="Profile">
      <h2 className="text-4xl font-bold ml-8 my-8">Support</h2>
      <SubNavigation items={NavItems} />
      <Outlet />
    </Container3>
  );
};

export default Support;
