import Icon from "Components/Icon";
import React from "react";
import { FaYelp, FaGoogle, FaFacebookF, FaList } from "react-icons/fa";
import StarRating from "Components/StarRating";
import ReviewModal from "./ReviewModal";

const getIcon = (directory) => {
  switch (directory) {
    case "google":
      return <Icon name={FaGoogle} h="5" w="5" color="red" />;
    case "facebook":
      return <Icon name={FaFacebookF} h="5" w="5" color="blue" />;
    case "yelp":
      return <Icon name={FaYelp} h="5" w="5" color="orange" />;
    default:
      return FaList;
  }
};
const ReviewCard = ({ review, handleModal }) => {
  const updateReview = () => {
    console.log("clicked");
    handleModal(review);
  };
  return (
    <li
      className="col-span-1 flex flex-col text-center bg-white divide-y divide-gray-200 cursor-pointer"
      onClick={updateReview}
    >
      <div className="flex-1 flex flex-col p-8">
        {getIcon(review.directory)}
        {/* <ReviewSiteIcon site={review.directory} /> */}
        <h3 className="mt-6 text-gray-900 text-sm font-medium">
          {review.author}
        </h3>
        <dl className="mt-1 flex-grow flex flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-gray-500 text-sm truncate">{review.text}</dd>
          <dt className="sr-only">Role</dt>
          <dd className="mt-3">
            <StarRating count={review.rating} />
          </dd>
        </dl>
      </div>
    </li>
  );
};

export default ReviewCard;
